import React, { useState } from 'react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Stack,
	RadioGroup,
	Radio,
	Box,
	Heading,
	Divider,
} from '@chakra-ui/react'

const SortModal = ({ isOpen, onClose, onSort }) => {
	const [sortOption, setSortOption] = useState('')
	const [sortDirection, setSortDirection] = useState('asc')

	const handleSort = () => {
		const sortValue = `${sortOption}-${sortDirection}`
		onSort(sortValue)
		onClose()
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='md'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading size='md'>Sort Records</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box mb={4}>
						<Heading size='sm' mb={2}>
							Sort Option
						</Heading>
						<RadioGroup
							value={sortOption}
							onChange={(value) => setSortOption(value)}
						>
							<Stack spacing={4}>
								<Radio value='username'>Full Name</Radio>
								<Radio value='role'>Account Type</Radio>
								<Radio value='email'>Email Address</Radio>
								<Radio value='subscription'>Date Joined</Radio>
							</Stack>
						</RadioGroup>
					</Box>
					<Divider mb={4} />
					<Box>
						<Heading size='sm' mb={2}>
							Sort Direction
						</Heading>
						<RadioGroup
							value={sortDirection}
							onChange={(value) => setSortDirection(value)}
						>
							<Stack spacing={4} direction='row'>
								<Radio value='asc'>Ascending</Radio>
								<Radio value='desc'>Descending</Radio>
							</Stack>
						</RadioGroup>
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button colorScheme='telegram' mr={3} onClick={handleSort}>
						Apply Sort
					</Button>
					<Button variant='ghost' onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default SortModal
