import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { Auth0Provider } from "@auth0/auth0-react";

import { AuthProvider } from "contexts/AuthContext";
import { GlobalProvider } from "contexts/GlobalContext";

import PrivateRoute from "./components/PrivateRoutes";
import { SettingsProvider } from "contexts/SettingsContext";
import { Toaster } from "react-hot-toast";
//import { ReactFlowProvider } from "reactflow";
import { QuickstartProvider } from "contexts/QuickContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "layout";

//import StripeContext from 'contexts/StripeContext';


// Fetch environment variables
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const redirectUri = `${window.location.origin}/#${localStorage.getItem("lastVisitedPage") || "/admin/dev"}`;
debugger;

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Toaster position="top-right" />
      <ThemeEditorProvider>
        <HashRouter>
          <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
              redirect_uri: redirectUri,
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
          >
            <GlobalProvider>
              <SettingsProvider>
                <QuickstartProvider>

                  <Switch>
                    <Layout />
                  </Switch>

            

                </QuickstartProvider>

              </SettingsProvider>
            </GlobalProvider>
          </Auth0Provider>
        </HashRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
