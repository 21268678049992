import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Radio,
  RadioGroup,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputGroup,
  InputLeftAddon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Tag,
  List,
  ListItem,
  ListIcon,
  Flex,
} from "@chakra-ui/react";
import agentsJson from "../../../data/agentsExample.json"
import { FaAngleLeft, FaChevronRight } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { FaSort } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { BsPersonFillCheck } from "react-icons/bs";
import { set } from "lodash";
import { FaUser, FaEnvelope, FaPhone, FaUserFriends } from 'react-icons/fa';

const Agents = () => {
  //state that will save agent information
  const [agents, setAgents] = useState([])
  //state that the data pagination will carry
  const [pag, setPag] = useState(1)
  //state that has a boolean which will show a modal about filters
  const [showFilter, setShowFilter] = useState(false);
  // state that has a boolean which will show a modal about sorting
  const [showSort, setShowSort] = useState(false);

  const [dateFilter, setDateFilter] = useState("");
  const [sortBy, setSortBy] = useState("Default");
  const [sortOrder, setSortOrder] = useState("asc");
  //creates a state which will be a boolean that will say if the page is on a mobile device or not
  const [isMobile, setIsMobile] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [clients, setClients] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const cancelRef = useRef();

  //returns a boolean which returns true if the user is authenticated
  useEffect(() => {
    setAgents(agentsJson)
  }, [])


  // Here the logic will be applied to filter and disappear the modal
  const handleFilterApply = () => {
    if (dateFilter === "standard") {
      setAgents(agentsJson.filter((a) => a.subscriptiontier === "standard"))
    }
    else if (dateFilter === "premium") {
      setAgents(agentsJson.filter((a) => a.subscriptiontier === "premium"))
    }
    else if (dateFilter === "organization") {
      setAgents(agentsJson.filter((a) => a.subscriptiontier === "organization"))
    }
    else if (dateFilter === "None") {
      setAgents(agentsJson)

    }
    setDateFilter("")
    setShowFilter(false);
  };
  //Here the logic will be applied to order the data and the modal will disappear
  const handleSort = () => {
    if (sortBy === "Default") {
      setAgents(agentsJson)
    }
    if (sortOrder === "asc") {
      if (sortBy === "Name") {
        setAgents(agents.sort((a, b) => a.name.localeCompare(b.name)))
      }
      else if (sortBy === "LastName") {
        setAgents(agents.sort((a, b) => a.lastName.localeCompare(b.lastName)))
      }
      else if (sortBy === "Email") {
        setAgents(agents.sort((a, b) => a.email.localeCompare(b.email)))
      }
    }
    else if (sortOrder === "desc") {
      if (sortBy === "Name") {
        setAgents(agents.sort((a, b) => b.name.localeCompare(a.name)))
      }
      else if (sortBy === "LastName") {
        setAgents(agents.sort((a, b) => b.lastName.localeCompare(a.lastName)))
      }
      else if (sortBy === "Email") {
        setAgents(agents.sort((a, b) => b.email.localeCompare(a.email)))
      }

    }

    setSortBy("Default")
    setSortOrder("asc")
    setShowSort(false);
  };


  //detects if the page is on a mobile device or not and saves it in the state
  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.innerWidth <= 768;
      setIsMobile(isMobileDevice);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClients = (name, lastName) => {
    let auxClients = agents.find((a) => a.name === name && a.lastName === lastName)
    setClients(auxClients.clients);
    setShowClients(!showClients);
  }
  const filterData = (data) => {
    return data.filter(user =>
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.phone.includes(searchQuery))
  }
  const handleInputChange = (e) => {
    if (e.target.value.length <= 1) {
      setAgents(agentsJson)
    }
    else {
      setAgents(filterData(agents));
    }
    setSearchQuery(e.target.value);
  };
  const handleDelete = (agent) => {
    setIsDelete(true);
    setSelectedAgent(agent);
  }
  const handleInfo = (agent) => {
    setSelectedAgent(agent);
    setShowInfo(true);
  }
  return (
    // main componente
    <Box w={"100%"} h={"100vh"} p={isMobile ? 1 : 5} display={"flex"} flexDirection={"column"} alignItems={"center"}>


      {/* modal showing filtering options */}
      <Modal isOpen={showFilter} onClose={() => setShowFilter(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            {/* Contenido del filtro */}
            <Select placeholder="Select category" mb={4} onChange={(e) => setDateFilter(e.target.value)}>
              <option value="standard">standard</option>
              <option value="premium">premium</option>
              <option value="organization">organization</option>
              <option value="None">None</option>
              {/* Más opciones de fecha */}
            </Select>


            <Button colorScheme="blue" onClick={handleFilterApply}>
              Apply Filters
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* modal showing sorting options             */}
      <Modal isOpen={showSort} onClose={() => setShowSort(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sort Options</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Contenido de ordenamiento */}
            <Select placeholder="Sort By" mb={4} onChange={(e) => setSortBy(e.target.value)}>
              <option value="Name">Name</option>
              <option value="LastName">Lastname</option>
              <option value="Email">Email</option>
              <option value="Default">Default</option>
              {/* Agregar más opciones de ordenamiento */}
            </Select>
            <RadioGroup defaultValue="asc" mb={4} onChange={(value) => setSortOrder(value)}>
              <Radio value="asc">Ascending</Radio>
              <Radio value="desc">Descending</Radio>
            </RadioGroup>
            <Button colorScheme="blue" onClick={handleSort}>
              Apply Sorting
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* {modal show when the user clicks on the more info button} */}
      <Modal isOpen={showClients} onClose={() => setShowClients(false)} size={"4xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Clients</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* table that shows the clients */}
            {clients && clients.length > 0 && (
              <Table variant="striped" colorScheme={"telegram"} mb={5}>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Lastname</Th>
                    <Th>Email</Th>
                    <Th>Plan</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {clients.map((client, index) => (
                    <Tr key={index}>
                      <Td>{client.name}</Td>
                      <Td>{client.lastname}</Td>
                      <Td>{client.email}</Td>
                      <Td>{client.plan}</Td>
                      <Td>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          gap={1}
                        // onClick={() => handleViewDetails(client)}
                        >
                          <FaInfoCircle /> Details
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* modal that shows the agent information */}
      {selectedAgent !== null && (

        <Modal isOpen={showInfo && selectedAgent !== null} onClose={() => setShowInfo(false)} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Flex alignItems="center">
                <FaUser color="blue" style={{ marginRight: '8px' }} />
                <Text>
                  {selectedAgent.name} {selectedAgent.lastName}
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box mb={4}>
                <Heading size="md" mb={2} textAlign={"center"}>
                  Agent Information
                </Heading>
                <List spacing={2}>
                  <ListItem>
                    <ListIcon as={FaEnvelope} color="orange" />
                    {selectedAgent.email}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaPhone} color="orange" />
                    {selectedAgent.phone}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={FaUserFriends} color="orange" />
                    <Text as="span" fontWeight="bold" mr={2}>
                      Subscription Tier:
                    </Text>
                    <Tag variant="outline" colorScheme="orange">
                      {selectedAgent.subscriptiontier}
                    </Tag>
                  </ListItem>
                </List>
              </Box>
              <Box mb={4}>
                <Heading size="md" mb={2} textAlign={"center"}>
                  Clients
                </Heading>
                <List spacing={3}>
                  {selectedAgent.clients.length > 0 && selectedAgent.clients.map((client, index) => (
                    <ListItem key={index}>
                      <Flex alignItems="center">
                        <FaUser style={{ marginRight: '8px' }} color="green"/>
                        <Text>
                          {client.name} {client.lastname}
                        </Text>
                        <Text ml={2} color="gray.500">
                          ({client.email})
                        </Text>
                        <Tag
                          variant="outline"
                          colorScheme="green"
                          ml="auto"
                          fontWeight="bold"
                        >
                          {client.plan}
                        </Tag>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {/* modal that shows the delete confirmation */}
      {selectedAgent !== null && (
        <AlertDialog
          isOpen={isDelete}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsDelete(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Person
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete {selectedAgent.name}?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsDelete(false)}>
                  Cancel
                </Button>
                <Button colorScheme="red" ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}

      {/* header */}
      <Box display={isMobile ? "block" : "flex"} w={"100%"} justifyContent={"center"} alignItems={"center"}>
        {/* Title */}
        <Heading size={"xl"} m={5} fontFamily={"monospace"} fontWeight={"bolder"}>
          Admin Center
        </Heading>
        {/* input to search */}
        <InputGroup h={10} marginTop={5} marginRight={10} marginLeft={isMobile ? 0 : 3} w={isMobile ? "100%" : "50%"}>
          <InputLeftAddon>
            <IoSearch />
          </InputLeftAddon>
          <Input
            placeholder="Search"
            w={"100%"}
            border={"1px solid blue"}
            value={searchQuery}
            onChange={handleInputChange}
          />
        </InputGroup>

        {/* group of buttons showing filter and sort modals */}
        <Box mb={4} textAlign="center" margin={5} display={"flex"} marginTop={12}>
          <Box marginRight={3}>

            <Button onClick={() => setShowFilter(true)} colorScheme="blue" mr={2}>
              <FaFilter />
            </Button>
            <Text>Filter</Text>
          </Box>
          <Box >
            <Button onClick={() => setShowSort(true)} colorScheme="blue">
              <FaSort />
            </Button>
            <Text>Sort</Text>
          </Box>
        </Box>
      </Box>
      {/* shows the data in a table */}
      <Card w={isMobile ? "100%" : "80%"} m={isMobile ? 0 : 1} bg={"gray.50"} paddingLeft={isMobile ? 0 : 10} overflowY={"scroll"}>
        <Box w={"100%"} h={"100%"} paddingTop={10} display={"flex"} flexDirection={"column"} alignItems={"start"} overflowY={"scroll"}>
          <TableContainer w={isMobile ? "100%" : ""} overflowY={"scroll"}>
            <Table variant={"simple"} w={isMobile ? "100%" : 1000} >
              {/* show properties */}
              <Thead>
                <Tr>
                  <Th border={"2px solid #2F3273"}>
                    First Name
                  </Th>
                  <Th border={"2px solid #F28F16"}>
                    Last Name
                  </Th>
                  <Th border={"2px solid #72A689"}>
                    Email
                  </Th>
                  <Th border={"2px solid #F23322"}>
                    Phone
                  </Th>
                  <Th border={"2px solid #206A73"}>
                    Subscription Tier
                  </Th>
                  <Th border={"2px solid #2F3273"}>
                    Actions
                  </Th>

                  <Th border={"2px solid #F28F16"}>
                    Clients
                  </Th>
                </Tr>
              </Thead>
              {/* map the data */}
              <Tbody>

                {agents && agents.length > 0 && (
                  agents.map((a) => {
                    return (
                      <Tr>
                        <Td>{a.name}</Td>
                        <Td>{a.lastName}</Td>
                        <Td>{a.email}</Td>
                        <Td>{a.phone}</Td>
                        <Td>{a.subscriptiontier}</Td>
                        <Td>
                          <Box display={"flex"} gap={5}>

                            <Button variant={"solid"} colorScheme="orange" size={"xs"} marginLeft={3} onClick={() => handleInfo(a)}>
                              <FaInfoCircle />
                            </Button>
                            <Button variant={"solid"} colorScheme={"red"} size={"xs"} onClick={() => handleDelete(a)}>
                              <MdDelete />
                            </Button>
                          </Box>
                        </Td>
                        <Td>
                          <Button variant={"solid"} colorScheme="blue" size={"xs"} onClick={() => handleClients(a.name, a.lastName)}>
                            <BsPersonFillCheck />
                          </Button>
                        </Td>
                      </Tr>
                    )
                  })
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      {/* pagination */}
      {/* <Box w={"100%"} display={"flex"} gap={2} justifyContent={"center"} m={2}>
        <Button variant={"solid"} colorScheme="blue" onClick={() => setPag(pag - 1)} size={"xs"}>
          <FaAngleLeft />
        </Button>
        <Text>{pag}</Text>
        <Button variant={"solid"} colorScheme="blue" onClick={() => setPag(pag + 1)} size={"xs"}>
          <FaChevronRight />
        </Button>
      </Box> */}
    </Box>
  );
}

export default Agents;