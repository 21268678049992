import React, { useEffect, useState } from 'react';
import { useParams, useHistory, } from 'react-router-dom';
import { Box, Text, Flex, Spinner, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const Validate = () => {
    const toast = useToast();
    const history = useHistory();
    const { id, accCode, formCode, email } = useParams();
    const { colorMode, toggleColorMode } = useColorMode();
    const [response, setResponse] = useState(null);
    const bg = useColorModeValue('gray.100', 'gray.800');
    const color = useColorModeValue('black', 'white');

    useEffect(() => {
        const validate = async () => {




            await axios.get(`${process.env.REACT_APP_API_URL}/formclient/auth/${id}`, {
                params: {
                    accCode,
                    formCode,
                    email
                }
            })
                .then(res => {
                    setResponse(res.data);
                    if (res.data.message === 'Unauthorized') {
                        toast({
                            title: 'Unauthorized',
                            description: 'You are not authorized to view this page.',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                    } else if (res.data.message === 'Internal Server Error') {
                        toast({
                            title: 'Internal Server Error',
                            description: 'An error occurred while validating your request.',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                    else {
                        localStorage.setItem('accCode', accCode);
                        localStorage.setItem('formCode', formCode);
                        localStorage.setItem('email', email);
                        localStorage.setItem('property', email);
                        console.log("agent data",res.data);
                        //sets the current agent if authorized
                        localStorage.setItem('curragent', JSON.stringify(res.data.agentcode));
                        localStorage.setItem('listingname', res.data.listingname);

                        
                        toast({
                            title: 'Validation successful',
                            description: 'You are now being redirected to the form page.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    }

                    history.push('/formclient/' + email + '/' + formCode);
                })
                .catch(err => {
                    console.log(err);
                    toast({
                        title: 'An error occurred',
                        description: 'An error occurred while validating your request.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                });
        }
        validate();
    }, []);



    return (
        <Flex
            direction="column"
            justify="center"
            align="center"
            height="100vh"
            bg={bg}
            color={color}
            p={5}
        >
            <Box
                p={8}
                maxWidth="400px"
                borderWidth={1}
                borderRadius="lg"
                overflow="hidden"
                textAlign="center"
                boxShadow="lg"
                bg={useColorModeValue('white', 'gray.700')}
            >
                <Spinner size="xl" color="teal.500" thickness="4px" speed="0.65s" />
                <Text fontSize="2xl" mt={4} display={"flex"}>
                    Validating <Text color={"teal"}>...</Text>
                </Text>
                <Flex justify="center" align="center" mt={4}>
                    <FaCheckCircle color="teal" size="24px" />
                </Flex>
            </Box>
        </Flex>
    );
}

export default Validate;
