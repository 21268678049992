import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Select,
  Button,
  Avatar,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  useDisclosure,
  Checkbox,
  CheckboxGroup,
  VStack,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
  IconButton,
} from '@chakra-ui/react';
import axios from 'axios';
import { FaCheck, FaEdit, FaSave, FaHome } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { BiSolidMessageAltX } from 'react-icons/bi';
import { GoMoveToEnd } from 'react-icons/go';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

const informationexample = {
  text: 'Business hours: Mon - Fri, 9am - 6pm\nRequirements for applying for a mortgage loan:\nMinimum income of $3,000 USD\nCredit history of at least 2 years\nMinimum savings of $10,000 USD\nPhone: +1 (555) 123-4567\nEmail: "info@company.com"',
};

export default function Component() {
  const history = useHistory();
  const [tone, setTone] = useState('friendly');
  const [toneResponse, setToneResponse] = useState('friendly');
  const [information, setInformation] = useState(informationexample);
  const [clients, setClients] = useState([]);
  const [agentDetails, setAgentDetails] = useState({
    name: '',
    phone: '',
    email: '',
    location: '',
    availability: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [infoModal, setInfoModal] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({
    name: 'Keller Realtors',
    address: '123 Main St, Anytown USA',
    phone: '+1 (555) 987-6543',
    email: 'info@company.com',
  });
  const [typeModal, setTypeModal] = useState('');
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [property, setProperty] = useState({ name: '', value: '' });
  const [messages, setMessages] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [availability, setAvailability] = useState({
    Monday: [9, 17],
    Tuesday: [9, 17],
    Wednesday: [9, 17],
    Thursday: [9, 17],
    Friday: [9, 17],
    Saturday: [9, 17],
    Sunday: [9, 17]
  });
  const [availableDays, setAvailableDays] = useState([]);
  const [propertys, setPropertys] = useState([]);
  const [agent, setAgent] = useState(null);
  const { user } = useAuth0();

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    history.push('/admin/dev');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatHour = (hour) => `${hour}:00`;

  const handleDaysChange = (selectedDays) => {
    setAvailableDays(selectedDays);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (selectedClient === null) return;
        else {
          const propertysClient = await axios.get(
            `${process.env.REACT_APP_API_URL}/property/byclient/${selectedClient.id}`
          );
          console.log(propertysClient.data);
          setPropertys(propertysClient.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [selectedClient]);

  useEffect(() => {
    async function fetchData() {
      try {
        const responseClients = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/agentemail/${user.email}`
        );
        if (responseClients && responseClients.data && responseClients.data.length > 0) {
          console.log(responseClients.data);
          setClients(responseClients.data);

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/clients/messages/${responseClients.data[0].id}`
          );
          if (response && response.data && response.data.messages) {
            setMessages(response.data.messages);
          }

          const client1 = await axios.get(
            process.env.REACT_APP_API_URL + '/clients/' + responseClients.data[0].id
          );
          if (client1 && client1.data && client1.data.client) {
            setSelectedClient(client1.data.client);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const handleToneChange = (e) => {
    setTone(e.target.value);
  };

  const saveParameters = async () => {
    console.log('saveParameters');
    try {
      const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`);
      const propertysClient = await axios.get(
        `${process.env.REACT_APP_API_URL}/property/byclient/${selectedClient.id}`
      );
      console.log(agent);
      setAgent(agent.data.agent);
      const dataparameters = {
        tone: toneResponse,
        rangehours: JSON.stringify(availability),
        agentid: agent.data.agent.id.toString(),
        clientid: selectedClient.id.toString(),
        companyinformation: information.text,
        agentinformation: JSON.stringify(agentDetails),
        companydetails: JSON.stringify(companyDetails),
        rangedays: JSON.stringify(availableDays),
        interesedpropertys: propertysClient.data,
      };
      try {
        const exist = await axios.get(
          `${process.env.REACT_APP_API_URL}/ai/existParameter/${agent.data.agent.id}/${selectedClient.id}`
        );
        console.log(exist.data);
        if (exist.data.error === 'Parameter not found') {
          console.log('create');
          const newP = await axios.post(`${process.env.REACT_APP_API_URL}/ai/createParameter`, dataparameters);
          setAgentDetails(JSON.parse(newP.data.agentinformation));
          setCompanyDetails(JSON.parse(newP.data.companydetails));
          setToneResponse(newP.data.tone);
          toast.success('Parameters saved successfully');
        } else {
          const updateParameter = await axios.put(
            `${process.env.REACT_APP_API_URL}/ai/updateParameter/${agent.data.agent.id}`,
            dataparameters
          );
          console.log(updateParameter.data);
          setAgentDetails(JSON.parse(updateParameter.data.agentinformation));
          setCompanyDetails(JSON.parse(updateParameter.data.companydetails));
          setToneResponse(updateParameter.data.tone);
          toast.success('Parameters saved successfully');
        }
      } catch (error) {
        console.log('por aca');
        const updateParameter = await axios.put(
          `${process.env.REACT_APP_API_URL}/ai/updateParameter/${agent.data.agent.id}`,
          dataparameters
        );

        setAgentDetails(JSON.parse(updateParameter.data.agentinformation));
        setCompanyDetails(JSON.parse(updateParameter.data.companydetails));
        setToneResponse(updateParameter.data.tone);
        toast.success('Parameters saved successfully');
      }
    } catch (error) {
      console.log(error);
      toast.error('An error occurred while saving the parameters');
    }
    setShowModal(false);
  };

  useEffect(() => {
    const fecthAgent = async () => {
      try {
        const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`);
        const parameters = await axios.get(`${process.env.REACT_APP_API_URL}/ai/parameter/${agent.data.agent.id}`);
        if (parameters) {
          setToneResponse(parameters.data.tone);
          setAgentDetails(JSON.parse(parameters.data.agentinformation));
          setCompanyDetails(JSON.parse(parameters.data.companydetails));
          setAvailability(JSON.parse(parameters.data.rangehours));
          setAvailableDays(JSON.parse(parameters.data.rangedays));
        }
        else {
          console.log(user.address);
          setAgentDetails({
            name: agent.data.agent.name,
            phone: agent.data.agent.phonenumber,
            email: agent.data.agent.email,
            location: user.address,
            availability: '9am - 5pm',
          });
        }
      } catch (error) {
        console.error('An error occurred while fetching the agent parameters');
        const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`);
        console.log(user.address)
        setAgentDetails({
          name: agent.data.agent.name,
          phone: !agent.data.agent.phonenumber ? '+15551234567' : agent.data.agent.phonenumber,
          email: agent.data.agent.email,
          location: !user.address ? 'USA' : user.address,
          availability: '9am - 5pm',
        });
      }
    };
    fecthAgent();
  }, []);

  const handleInfoModal = (type) => {
    setTypeModal(type);
    setShowModal(true);
    if (type === 'agent') {
      setInfoModal(Object.entries(agentDetails));
    } else if (type === 'company') {
      setInfoModal(Object.entries(companyDetails));
    } else {
      setInfoModal(Object.entries(information));
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    if (typeModal === 'agent') {
      const aux = { ...agentDetails };
      aux[name] = value;
      setAgentDetails(aux);
      setInfoModal(Object.entries(aux));
    } else if (typeModal === 'company') {
      setCompanyDetails({ ...companyDetails, [name]: value });
      setInfoModal(Object.entries({ ...companyDetails, [name]: value }));
    } else {
      setInformation({ ...information, text: value });
      setInfoModal(Object.entries({ ...information, text: value }));
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowPropertyModal(false);
  };

  const handleProperty = (e) => {
    const { name, value } = e.target;
    setProperty(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const saveProperty = () => {
    if (typeModal === 'info') {
      setInformation(prevState => ({
        ...prevState,
        [property.name]: property.value
      }));
      setInfoModal(Object.entries({
        ...information,
        [property.name]: property.value
      }));
    } else if (typeModal === 'company') {
      setCompanyDetails(prevState => ({
        ...prevState,
        [property.name]: property.value
      }));
      setInfoModal(Object.entries({
        ...companyDetails,
        [property.name]: property.value
      }));
    } else if (typeModal === 'agent') {
      setAgentDetails(prevState => ({
        ...prevState,
        [property.name]: property.value
      }));
      setInfoModal(Object.entries({
        ...agentDetails,
        [property.name]: property.value
      }));
    }
    setProperty({ name: '', value: '' });
    setShowPropertyModal(false);
  };


  const deleteProperty = (e) => {
    const { name } = e.target;
    if (typeModal === 'info') {
      const aux = { ...information };
      delete aux[name];
      setInformation(aux);
      setInfoModal(Object.entries(aux));
    } else if (typeModal === 'company') {
      const aux = { ...companyDetails };
      delete aux[name];
      setCompanyDetails(aux);
      setInfoModal(Object.entries(aux));
    } else if (typeModal === 'agent') {
      const aux = { ...agentDetails };
      delete aux[name];
      setAgentDetails(aux);
      setInfoModal(Object.entries(aux));
    }
  };

  const selectClient = async (client) => {
    setIsLoading(true);
    setSelectedClient(client);
    try {
      const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`);
      const parameters = await axios.get(
        `${process.env.REACT_APP_API_URL}/ai/existParameter/${agent.data.agent.id}/${client.id}`
      );
      if (!parameters.data.error) {
        setToneResponse(parameters.data.tone);
        setAgentDetails(JSON.parse(parameters.data.agentinformation));
        setCompanyDetails(JSON.parse(parameters.data.companydetails));
        setAvailability(JSON.parse(parameters.data.rangehours));
        setAvailableDays(JSON.parse(parameters.data.rangedays));
        //toast.success('Parameters fetched successfully');
      } else {
        setToneResponse('friendly');
        setAgentDetails({
          name: '',
          phone: '',
          email: '',
          location: '',
          availability: '',
        });
        setCompanyDetails({
          name: 'Keller Realtors',
          address: '123 Main St, Anytown USA',
          phone: '+1 (555) 987-6543',
          email: 'info@company.com',
        });
        setAvailability({
          Monday: [9, 17],
          Tuesday: [9, 17],
          Wednesday: [9, 17],
          Thursday: [9, 17],
          Friday: [9, 17],
          Saturday: [9, 17],
          Sunday: [9, 17]
        });
        setAvailableDays([]);
        console.error('Parameters not found');
      }
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/messages/${client.id}`
        );
        if (response.data.messages.length === 0) {
          //toast.error('No messages found');
        }
        if (!response.data.error) {
          setMessages(response.data.messages);
          //toast.success('Messages fetched successfully');
        }
      } catch (error) {
        toast.error('An error occurred while fetching the messages');
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const placeHolderValue = (clave) => {

    if (typeModal === 'agent') {
      if (clave === 'name') {
        return agentDetails.name;
      }
      if (clave === 'phone') {
        return agentDetails.phone;
      }
      if (clave === 'email') {
        return agentDetails.email;
      }
      if (clave === 'location') {
        return agentDetails.location
      }
      if (clave === 'availability') {
        return agentDetails.availability;
      }
    }
    else {
      if (clave === 'name') {
        return companyDetails.name;
      }
      if (clave === 'address') {
        return companyDetails.address;
      }
      if (clave === 'phone') {
        return companyDetails.phone;
      }
      if (clave === 'email') {
        return companyDetails.email;
      }
    }
  }

  return (
    <Flex w={'full'} h={'calc(100vh - 0px)'}>
      {/* Modal */}
      {infoModal.length > 0 && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit your info {typeModal}</ModalHeader>
            <ModalCloseButton onClick={() => handleCloseModal()} />
            <ModalBody>
              {infoModal.map(([key, value]) => (
                <Flex justifyContent={'start'} align='center' mb={3} gap={3} key={key}>
                  <Text color='gray.500' dark='gray.400' mr={5} w={"25%"}>
                    {key}
                  </Text>
                  <Input
                    w={300}
                    name={key}
                    onChange={handleChange}
                    value={value}
                    placeholder={placeHolderValue(key)}
                  />
                  <IconButton
                    variant={'solid'}
                    colorScheme={'red'}
                    size={'md'}
                    name={key}
                    icon={<MdDelete/>}
                    onClick={(e) => deleteProperty(e)}
                    
                  >
                    {/* <MdDelete/> */}
                  </IconButton>
                </Flex>
              ))}
            </ModalBody>
            <ModalFooter justifyContent={'space-around'}>
              <Button colorScheme={'green'} onClick={() => setShowPropertyModal(true)}>
                Add Field
              </Button>
              <Button colorScheme='messenger' mr={3} onClick={() => saveParameters()}>
                Save
              </Button>
            </ModalFooter>
            {showPropertyModal && (
              <Flex m={3} gap={2}>
                <Input placeholder='Field Name' name='name' onChange={handleProperty} />
                <Input placeholder='Field Value' name='value' onChange={handleProperty} />
                <Button colorScheme={'blue'} variant={'outline'} onClick={saveProperty}>
                  <FaCheck size={50} />
                </Button>
              </Flex>
            )}
          </ModalContent>
        </Modal>
      )}

      <Box
        bg='gray.100'
        dark='gray.800'
        flex={1}
        p='6'
        borderRightWidth='1'
        borderRightColor='gray.200'
      >
        <Box spacing='6'>
          <Box spacing='2'>
          </Box>
          <Box spacing='2' mt={10} display={'flex'} flexDir={'column'} gap={3}>
            <Text fontSize='lg' fontWeight='semibold'>
              General Settings
            </Text>
            <Box spacing='2' fontSize='sm'>
              <Flex justify='space-between' align='center' mb={3}>
                <Text color='gray.500' dark='gray.400'>
                  Agent information
                </Text>
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme={'messenger'}
                  onClick={() => handleInfoModal('agent')}
                >
                  <FaEdit />
                </Button>
              </Flex>
              <Flex justify='space-between' align='center'>
                <Text color='gray.500' dark='gray.400'>
                  Company Details
                </Text>
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme={'messenger'}
                  onClick={() => handleInfoModal('company')}
                >
                  <FaEdit />
                </Button>
              </Flex>
              <Box mt={5}>
                {/* <Button
                  colorScheme='messenger'
                  size='sm'
                  variant={'outline'}
                  gap={2}
                  onClick={saveParameters}
                >
                  Save <FaSave />
                </Button> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Flex flex='6' bg='gray.50' dark='gray.900' overflow='hidden'>
        <Flex h='full' w={'100%'}>
          <Box
            bg='white'
            dark='gray.800'
            flex={1}
            borderRightWidth='1'
            borderRightColor='gray.200'
            overflowY='auto'
          >
            <Box p='4' spacing='4'>
              <Flex justify='space-between' align='center'>
                <Text fontSize='lg' fontWeight='semibold'>
                  Clients
                </Text>
              </Flex>
              <Box spacing='2'>
                {clients.length > 0 ? (
                  clients.map((client) => {
                    return (
                      <Flex
                        p='2'
                        rounded='md'
                        _hover={{ bg: 'gray.100', dark: 'gray.700' }}
                        cursor='pointer'
                        align='center'
                        gap='2'
                        bg={
                          selectedClient !== null && selectedClient.id === client.id
                            ? 'gray.200'
                            : 'white'
                        }
                        onClick={() => selectClient(client)}
                      >
                        <Avatar name={client.name} src='/placeholder-avatar.jpg' boxSize='10' />
                        <Box flex='1'>
                          <Text fontWeight='medium'>{client.name}</Text>
                          <Text fontSize='sm' color='gray.500' dark='gray.400'>
                            Last conversation: {new Date(client.lastactive).getHours().toLocaleString()} hours ago
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })
                ) : (
                  <Box>
                    <Text fontSize='sm' color='gray.500' dark='gray.400'>
                      No clients found
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Flex
            flex='2'
            bg='gray.50'
            dark='gray.900'
            overflowY='auto'
            w={500}
            maxWidth={500}
          >
            <Box
              p='6'
              spacing='6'
              width={'100%'}
              display={isLoading && 'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {isLoading ? (
                <Spinner size='xl' />
              ) : (
                messages.length > 0 ? (
                  messages.map((message) => {
                    return (
                      <Flex
                        gap='4'
                        align='start'
                        mb={5}
                        justify={message.from === 'client' ? 'start' : 'end'}
                        flexDir={message.from === 'IA' ? 'row-reverse' : 'row'}
                      >
                        <Avatar
                          name={message.from === 'client' ? message.nameclient : 'I A'}
                          src='/placeholder-avatar.jpg'
                          boxSize='10'
                        />
                        <Box
                          bg={message.from === 'client' ? 'white' : 'blue.500'}
                          dark='gray.800'
                          p='4'
                          rounded='md'
                          shadow='sm'
                          maxW='80%'
                          color={message.from === 'client' ? 'black' : 'white'}
                        >
                          <Text fontWeight='medium'>
                            {message.from === 'client' ? message.nameclient : 'Agent'}
                          </Text>
                          <Text
                            fontSize='sm'
                            dark='gray.400'
                            color={message.from === 'client' ? 'gray.400' : 'white'}
                          >
                            {message.content}
                          </Text>
                          <Text
                            fontSize='xs'
                            dark='gray.400'
                            mt='2'
                            color={message.from === 'client' ? 'gray.400' : 'white'}
                          >
                            {message.sent_time === null
                              ? 'shipping time not recorded'
                              : message.sent_time}
                          </Text>
                        </Box>
                      </Flex>
                    );
                  })
                ) : (
                  messages.length === 0 && (
                    <Box
                      w={'100%'}
                      h={'100%'}
                      display={'flex'}
                      flexDir={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <Text fontSize='sm' color='gray.500' dark='gray.400'>
                        No messages found
                        <BiSolidMessageAltX size={100} color='purple' />
                      </Text>
                    </Box>
                  )
                )
              )}
            </Box>
          </Flex>
          <Box
            bg='white'
            dark='gray.800'
            flex={1}
            borderLeftWidth='1'
            borderLeftColor='gray.200'
            overflowY='auto'
          >
            <Box p='6' spacing='6'>
              {selectedClient && (
                <>
                  <Box mb={5}>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='lg' fontWeight='semibold'>
                              Agent Details
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Box fontSize='sm' color='gray.500' mt='2'>
                            {Object.entries(agentDetails).map(([key, value]) => (
                              <Flex justify='space-between' align='center' mb={3}>
                                <Text>{key}:</Text>
                                <Text>{value}</Text>
                              </Flex>
                            ))}
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                  <Box mb={5}>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='lg' fontWeight='semibold'>
                              Company Details
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Box fontSize='sm' color='gray.500' mt='2'>
                            {Object.entries(companyDetails).map(([key, value]) => (
                              <Flex justify='space-between' align='center' mb={3}>
                                <Text>{key}:</Text>
                                <Text>{value}</Text>
                              </Flex>
                            ))}
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                  <Box mb={5}>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box flex='1' textAlign='left' fontSize='lg' fontWeight='semibold'>
                              Chat Observation
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <Box fontSize='sm' color='gray.500' mt='2'>
                            <Text>Response tone: {tone}</Text>
                            <Text>Average response time: 2 minutes</Text>
                            <Text>Customer satisfaction: 4.8/5</Text>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Box>
                  <Box p={5} shadow='md' borderWidth='1px'>
                    <Flex>
                      <Text fontSize='xl' fontWeight='bold'>
                        Client Details
                      </Text>
                      <Button
                        ml='auto'
                        size='sm'
                        variant='outline'
                        colorScheme='messenger'
                        onClick={openModal} // Attach openModal to onClick event
                      >
                        <GoMoveToEnd />
                      </Button>
                    </Flex>
                    <Text mt={4}>Name: {selectedClient.name}</Text>
                    {daysOfWeek.map((day) => (
                      <Box key={day} mb={4}>
                        <Text mt={4}>
                          {day} Availability:
                          <br />
                          {formatHour(availability[day][0])} to {formatHour(availability[day][1])}
                        </Text>
                        <RangeSlider
                          defaultValue={availability[day]}
                          min={0}
                          max={23}
                          step={1}
                          onChange={(value) =>
                            setAvailability((prev) => ({ ...prev, [day]: value }))
                          }
                        >
                          <RangeSliderTrack>
                            <RangeSliderFilledTrack />
                          </RangeSliderTrack>
                          <RangeSliderThumb index={0} />
                          <RangeSliderThumb index={1} />
                        </RangeSlider>
                      </Box>
                    ))}

                    <Box
                      p={5}
                      shadow='md'
                      borderWidth='1px'
                      borderRadius='lg'
                      maxW='400px'
                      m='20px auto'
                      bg='white'
                      color='gray.700'
                    >
                      <VStack spacing={5} align='stretch'>
                        <FormControl>
                          <FormLabel>Select Available Days</FormLabel>
                          <CheckboxGroup value={availableDays} onChange={handleDaysChange}>
                            <VStack align='start'>
                              {daysOfWeek.map((day) => (
                                <Checkbox key={day} value={day} isChecked={availableDays.includes(day)}>
                                  {day}
                                </Checkbox>
                              ))}
                            </VStack>
                          </CheckboxGroup>
                        </FormControl>

                        <Box>
                          <Text fontSize='lg' fontWeight='bold'>
                            Selected Days:
                          </Text>
                          {availableDays.length > 0 ? (
                            <Text>{availableDays.join(', ')}</Text>
                          ) : (
                            <Text color='gray.500'>No days selected</Text>
                          )}
                        </Box>
                      </VStack>
                    </Box>
                    <Text mt={4}>Tone:</Text>
                    <Select
                      placeholder='Select response tone'
                      value={toneResponse}
                      onChange={(e) => setToneResponse(e.target.value)}
                    >
                      <option value='pleasant'>Pleasant</option>
                      <option value='business'>Business</option>
                      <option value='friendly'>Friendly</option>
                      <option value='excited'>Excited</option>
                    </Select>
                    <Button mt={4} onClick={onOpen} variant={'outline'} colorScheme={'purple'} size={'xs'}>
                      Interested Properties
                    </Button>

                    {/* Modal for displaying properties */}
                    <Modal isOpen={isOpen} onClose={onClose} size='lg'>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{selectedClient !== null && selectedClient.name} Interested Properties</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          <VStack spacing={4} align='stretch'>
                            {propertys.length > 0 &&
                              propertys.map((property, index) => (
                                <Box
                                  key={index}
                                  p={4}
                                  borderWidth='1px'
                                  borderRadius='lg'
                                  overflow='hidden'
                                  _hover={{ boxShadow: 'lg' }}
                                >
                                  <HStack spacing={3}>
                                    <Icon as={FaHome} w={6} h={6} color='teal.500' />
                                    <Text fontWeight='medium'>{property.name}</Text>
                                    <Text fontWeight='medium'>{property.address}</Text>
                                  </HStack>
                                </Box>
                              ))}
                          </VStack>
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme='teal' mr={3} onClick={onClose}>
                            Close
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
