import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

export const InviteModal = (props) => {
  const [emailToInvite, setEmailToInvite] = useState('');

  const handleSentInvite = useCallback(() => {
    props.onInvite(emailToInvite);
    props.onClose();
  }, [emailToInvite, props.onInvite, props.onClose]);

  return (
    <Modal isOpen={props.open} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Email address</FormLabel>
            <Input
              type='email'
              value={emailToInvite}
              onChange={e => setEmailToInvite(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Box gap={'12px'} display={'flex'}>
            <Button onClick={props.onClose} borderRadius={'8px'} border={'1px solid black'}>
              Cancel
            </Button>
            <Button colorScheme={'telegram'} borderRadius={'8px'} onClick={handleSentInvite}>
              Send Invitation
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
