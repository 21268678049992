import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { useQuickContext } from 'contexts/QuickContext'
import Plaidlink from './component/plaidlink'

export default function Plaid() {
	const [selectedPerson, setSelectedPerson] = useState(null)
	const [result, setResult] = useState(null)
	const [linkToken2, setLinkToken2] = useState(null)

	const { linkToken, isPaymentInitiation, dispatch } = useQuickContext()

	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			minHeight='100vh'
			position='relative'
		>
			<Box
				position='fixed'
				top='50%'
				left='50%'
				transform='translate(-50%, -50%)'
				zIndex='999'
			>
				<Plaidlink />
			</Box>
		</Box>
	)
}
