import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Heading, Button, Text } from '@chakra-ui/react';
import axios from 'axios';

const SubscriptionPage = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const sanitizeInput = (input) => {
    return input ? input.replace(/[^a-zA-Z0-9_-]/g, '') : '';
  };

  useEffect(() => {
    if (user && isAuthenticated) {

      setEmail(sanitizeInput(user.email));
      setUserId(sanitizeInput(user.sub));

      setDataLoaded(true);
    }
  }, [user, isAuthenticated]);

  const handleManageBilling = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/create-customer-portal-session`, {
        customerId: userId, // Assuming userId is the Stripe customer ID
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Redirect to the Stripe customer portal
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };

  return (
    <Box>
      <Heading as="h2" size="xl" textAlign="center" mb={4}>
        Manage Your Subscription
      </Heading>
      {dataLoaded && (
        <Box textAlign="center">
          <Text>Email: {email}</Text>
          <Button mt={4} onClick={handleManageBilling}>
            Manage Billing
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionPage;
