import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import UserLayout from 'layouts/user';
import Ophouseform from './views/admin/openhouseform/index';
import PrivateRoute from 'components/PrivateRoutes';
import SubscriptionPage from './views/admin/stripe/subscription';
import Validate from './views/admin/validate/Validate';
import PricingTablePage from './views/admin/stripe/pricing';

export const Layout = () => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0(); // Include isLoading to handle loading state

  useEffect(() => {
    localStorage.setItem("lastVisitedPage", location.pathname);
  }, [location]);

  if (isLoading) {
    return <div>Loading...</div>; // Add a loading state to handle Auth0 loading
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/error`} render={() => <div>Error: Invalid or expired key</div>} />
        <Route exact path={`/formclient/:email/:formCode`} component={Ophouseform} />
        <Route exact path={`/validate/:id/:accCode/:formCode/:email`} component={Validate} />
        <Route path="/Subscription" component={SubscriptionPage} />
        <Route path="/pricing" component={PricingTablePage} />
        {!isAuthenticated && <Route path={'/user'} component={UserLayout} />}
        {isAuthenticated && <Route path={`/admin`} component={AdminLayout} />}
        {isAuthenticated && <Route path={`/forms`} component={AuthLayout} />}
        {isAuthenticated && <PrivateRoute path={`/rtl`} component={RtlLayout} />}
        {!isAuthenticated && <Redirect exact from="*" to="/auth/signin" />}
        {!isAuthenticated && <Redirect exact from="/" to="/auth/signin" />}
      </Switch>
    </React.Fragment>
  );
};
