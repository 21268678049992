import {
	Box,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Button,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Icon,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	IconButton,
	ModalFooter,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
  } from '@chakra-ui/react';
  import { useState, useEffect, useRef } from 'react';
  import { BsFillPeopleFill } from 'react-icons/bs';
  import { MdAdd, MdDeleteOutline, MdMoreVert, MdQrCode, MdEdit,MdPeople  } from 'react-icons/md';
  import { FaPlus } from 'react-icons/fa';
  import { DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
  import { useHistory } from 'react-router-dom';
  import QRCode from 'qrcode.react';
  import * as htmlToImage from 'html-to-image';
  import toast from 'react-hot-toast';
  import axios from 'axios';
  import { useAuth0 } from '@auth0/auth0-react';
  
  export default function Openhouse() {
	const { user } = useAuth0();
	const [selectedPerson, setSelectedPerson] = useState(null);
	const [result, setResult] = useState(null);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [formValues, setFormValues] = useState({
	  name: '',
	  address: '',
	  contacts: '',
	  'agent contacts': '',
	  house: '',
	  bathrooms: '',
	  price: 0,
	  rooms: 0,
	});
	const [isMobile, setIsMobile] = useState(false);
	const [isAddRequired, setIsAddRequired] = useState(false);
	const [errors, setErrors] = useState({});
	const [proprtyErrors, setPropertyErrors] = useState({});
	const [isCustomFieldRequired, setIsCustomFieldRequired] = useState(false);
	const [requireQrCode, setRequireQrCode] = useState(false);
	const [house, setHouse] = useState({});
	const [agentInfo, setAgentInfo] = useState(null);
	const [inputAgent, setInputAgent] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [propertyList, setPropertyList] = useState([]);
	const [isEditRequired, setIsEditRequired] = useState(false);
	const [editFields, setEditFields] = useState([]);
	const qrCodeRef = useRef(null);
  
	const history = useHistory();
  
	const handleClick = (person) => {
	  setSelectedPerson(person);
	};
  
	const [fields, setFields] = useState([
	  { id: '1', label: 'Name', name: 'Name', value: '', type: 'text' },
	  { id: '2', label: 'Address', name: 'Address', value: '', type: 'text' },
	  {
		id: '4',
		label: 'Agent contacts',
		name: 'AgentContacts',
		value: '',
		type: 'text',
	  },
	  { id: '5', label: 'Property Type', name: 'Property Type', value: '', type: 'text' },
	  { id: '6', label: 'Bathrooms', name: 'Bathrooms', value: '', type: 'text' },
	  { id: '9', label: 'Rooms', name: 'Notes', value: '', type: 'text' },
	  { id: '8', label: 'Price', name: 'Price', value: '', type: 'text' },
	]);
  
	const [customField, setCustomField] = useState({
	  label: '',
	  name: '',
	  value: '',
	  type: '',
	});
  
	const validateCustomFields = () => {
	  const newErrors = {};
	  const { label, name, type } = customField;
  
	  if (!label) {
		newErrors.label = 'Label is required';
	  }
	  if (!name) {
		newErrors.name = 'Name is required';
	  }
	  if (!type) {
		newErrors.type = 'Type is required';
	  }
	  return newErrors;
	};
  
	useEffect(() => {
	  const fetchAgents = async () => {
		console.log('User:', user);
		try {
		  if (user && agentInfo === null) {
			const response = await axios.get(
			  process.env.REACT_APP_BACKEND + '/agents/byemail/' + user.email
			);
  
			console.log('response', response);
  
			if (response.data.agent !== null) {
			  console.log(response);
			  setAgentInfo(response.data.agent);
			  try {
				const listings = await axios.get(
				  process.env.REACT_APP_BACKEND + '/property/' + response.data.agent.id
				);
				setPropertyList(listings.data);
				console.success('Agent properties found');
			  } catch (error) {
				console.log('Error fetching agent properties:', error);
				console.error('Error fetching agent properties');
			  }
			}
		  }
		} catch (error) {
		  console.log('Error fetching agents:', error);
		  try {
			const response = await axios.get(
			  process.env.REACT_APP_BACKEND + '/agents/agentName/' + 'developer yuugo'
			);
  
			console.log('response', response);
  
			if (response.data.agent !== null) {
			  console.log(response);
			  setAgentInfo(response.data.agent);
			}
		  } catch (error) {
			console.log('Error fetching agents:', error);
		  }
		}
	  };
	  fetchAgents();
	}, [user]);
  
	const handleAddField = () => {
	  const newFieldId = fields.length + 1;
	  if (Object.keys(validateCustomFields()).length > 0) {
		setErrors(validateCustomFields());
		return;
	  }
	  setFields([...fields, { ...customField, id: newFieldId }]);
	  setIsCustomFieldRequired(false);
	  setCustomField({ label: '', name: '', value: '', type: '' });
	  setErrors({});
	};
  
	const handleFieldChange = (index, fieldName, fieldValue) => {
	  const updatedFields = [...fields];
	  let field = updatedFields[index];
	  field = { ...field, value: fieldValue };
	  updatedFields[index] = field;
	  setFields(updatedFields);
	  setFormValues({ ...formValues, [fieldName.toLowerCase()]: fieldValue });
	  if (Object.keys(proprtyErrors).length > 0) {
		delete proprtyErrors[fieldName];
		setPropertyErrors({ ...proprtyErrors });
	  }
	};
  
	const handleEditFieldChange = (index, fieldName, fieldValue) => {
	  const updatedFields = [...editFields];
	  let field = updatedFields[index];
	  field = { ...field, value: fieldValue };
	  updatedFields[index] = field;
	  setEditFields(updatedFields);
	};
  
	const handleDeleteField = (id) => {
	  setFields(fields.filter((field) => field.id !== id));
	};
  
	const handleChangeCustomField = (field, value) => {
	  if (Object.keys(errors).length > 0) {
		delete errors[field];
		setErrors(errors);
	  }
	  setCustomField({ ...customField, [field]: value });
	};
  
	useEffect(() => {
	  const handleResize = () => {
		const isMobileDevice = window.innerWidth <= 768;
		setIsMobile(isMobileDevice);
	  };
  
	  window.addEventListener('resize', handleResize);
  
	  handleResize();
  
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	const handleCloseModal = () => {
	  setIsAddRequired(false);
	  setPropertyErrors({});
	  setFields([
		{ id: '1', label: 'Name', name: 'Name', value: '', type: 'text' },
		{ id: '2', label: 'Address', name: 'Address', value: '', type: 'text' },
		{
		  id: '4',
		  label: 'Agent contacts',
		  name: 'AgentContacts',
		  value: '',
		  type: 'text',
		},
		{ id: '5', label: 'Property Type', name: 'Property Type', value: '', type: 'text' },
		{ id: '6', label: 'Bathrooms', name: 'Bathrooms', value: '', type: 'text' },
		{ id: '9', label: 'Rooms', name: 'Notes', value: '', type: 'text' },
		{ id: '8', label: 'Price', name: 'Price', value: '', type: 'text' },
	  ]);
	};
  
	const handleCloseEditModal = () => {
	  setIsEditRequired(false);
	  setEditFields([]);
	};
  
	const handleCloseCustomModal = () => {
	  setIsCustomFieldRequired(false);
	  setCustomField({ label: '', name: '', value: '', type: '' });
	  setErrors({});
	};
  
	const handleValidatePropertyForm = () => {
	  let newErrors = {};
	  fields.forEach((field) => {
		console.log('Field in loop:', field);
		if (!field.value) {
		  let fieldKey = field.label;
		  newErrors = { ...newErrors, [fieldKey]: `${field.label} is required` };
		}
	  });
	  setPropertyErrors({ ...newErrors });
	  return newErrors;
	};
  
	const handleSubmitPropertyForm = async () => {
	  if (Object.keys(handleValidatePropertyForm()).length > 0) {
		return;
	  }
	  try {
		const property = await axios.post(process.env.REACT_APP_BACKEND + '/property/create', {
		  name: formValues.name,
		  address: formValues.address,
		  price: formValues.price,
		  agentid: agentInfo.id.toString(),
		  contacts: formValues.contacts,
		  agentcontacts: formValues['agent contacts'],
		  house: formValues.house,
		  bathrooms: formValues.bathrooms,
		  rooms: formValues.rooms,
		});
		const agentNew = await axios.put(
		  process.env.REACT_APP_BACKEND + '/agents/update/' + agentInfo.id,
		  {
			...agentInfo,
			listing: [...agentInfo.listing, property.data.id],
		  }
		);
		setAgentInfo(agentNew.data.updatedAgent);
		setPropertyList([...propertyList, property.data]);
		toast.success('Property form submitted successfully');
	  } catch (error) {
		console.log('Error submitting property form:', error);
		toast.error('Error submitting property form');
	  }
	  handleCloseModal();
	};
  //+ selectedPerson.id
	const handleEditPropertyForm = async () => {
	  try {
		const updatedProperty = editFields.reduce((acc, field) => ({ ...acc, [field.name.toLowerCase()]: field.value }), {});
		await axios.put(process.env.REACT_APP_BACKEND + '/property/update' , updatedProperty);
		const listings = await axios.get(process.env.REACT_APP_BACKEND + '/property/' + agentInfo.id);
		setPropertyList(listings.data);
		toast.success('Property updated successfully');
	  } catch (error) {
		console.log('Error updating property:', error);
		toast.error('Error updating property');
	  }
	  handleCloseEditModal();
	};
  
	const handleNavigateToClient = () => {
	  history.push('dev');
	};
  
	const handleViewQrCode = (property = {}) => {
	  console.log(property);
	  setRequireQrCode(true);
	  setHouse(property);
	};
  
	const handleQrCodeClose = () => {
	  setRequireQrCode(false);
	  setHouse({});
	};
  
	const handleDownloadQrCode = () => {
	  htmlToImage
		.toPng(qrCodeRef.current.firstChild)
		.then(function (dataUrl) {
		  const a = document.createElement('a');
		  a.href = dataUrl;
		  a.download = `house-${house.generatedcode}_Qr.png`;
		  a.click();
		})
		.catch(function (error) {
		  console.error('Error generating QR code image:', error);
		});
	};
  
	const isThisFieldRequiredToNotShow = (field) => {
	  const notRequiredField = ['name'];
	  return !notRequiredField.includes(field);
	};
  
	const searchAgentByName = async () => {
	  try {
		const response = await axios.get(
		  process.env.REACT_APP_BACKEND + '/agents/agentName/' + inputAgent
		);
		setAgentInfo(response.data.agent);
		toast.success('Agent found');
	  } catch (error) {
		toast.error('Agent not found');
		console.log('Error fetching agent:', error);
	  }
	};
  
	const filteredListings = propertyList.filter((person) =>
	  person.name.toLowerCase().includes(searchQuery.toLowerCase())
	);
  
	const deleteProperty = async (property) => {
	  try {
		await axios.delete(process.env.REACT_APP_BACKEND + '/property/delete/' + property.id);
		const listings = await axios.get(process.env.REACT_APP_BACKEND + '/property/' + agentInfo.id);
		setPropertyList(listings.data);
		toast.success('Property deleted successfully');
	  } catch (error) {
		console.log('Error deleting property:', error);
		toast.error('Error deleting property');
	  }
	};
  
	const handleEdit = (property) => {
	  setSelectedPerson(property);
	  setEditFields(
		Object.keys(property).map((key) => ({
		  name: key,
		  value: property[key],
		  type: typeof property[key] === 'number' ? 'number' : 'text',
		}))
	  );
	  setIsEditRequired(true);
	};
  
	return (
	  <Box
		p={8}
		bg="white"
		borderRadius="lg"
		boxShadow="md"
		minHeight={'100vh'}
		width={'100%'}
		maxWidth="100%"
		margin="0 auto"
	  >
		<Text fontSize="3xl" fontWeight="bold" mb={8} textAlign="center">
		  My Listings
		</Text>
		<Box
		  w={'100%'}
		  h={'100%'}
		  display={isMobile ? 'block' : 'flex'}
		  alignItems={'center'}
		  gap={10}
		>
		  <Box w={'100%'}>
			<Flex
			  w={'100%'}
			  alignItems={'center'}
			  justifyContent={'space-between'}
			  flexDirection={{ base: 'column-reverse', lg: 'row' }}
			  gap={'8px'}
			>
			  <Box mb={2} width={{ base: '100%', lg: 'fit-content' }}>
				<FormControl>
				  <FormLabel>Search Listings</FormLabel>
				  <Input
					type={'text'}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				  />
				</FormControl>
			  </Box>
			  <Button
				leftIcon={<Icon as={FaPlus} />}
				colorScheme="blue"
				variant="outline"
				onClick={() => setIsAddRequired(true)}
				width={{ base: '100%', lg: 'fit-content' }}
			  >
				Add
			  </Button>
			</Flex>
			<Accordion allowMultiple allowToggle>
			  {agentInfo !== null &&
				filteredListings.map((person, index) => (
				  <AccordionItem
					key={index}
					_hover={{ backgroundColor: 'blue.100' }}
					borderRadius={10}
					bg={selectedPerson === person.name ? 'blue.100' : 'white'}
					marginBottom={5}
				  >
					<AccordionButton>
					  <Box flex="1" textAlign="left" display={'flex'}>
						<BsFillPeopleFill size={20} color="blue" />
						<Text ml={2}>{person.name}</Text>
					  </Box>
					  <Button
						variant={'solid'}
						colorScheme={'red'}
						m={2}
						onClick={() => deleteProperty(person)}
					  >
						<MdDeleteOutline />
					  </Button>
					  <Menu>
						<MenuButton
						  as={IconButton}
						  aria-label="Options"
						  icon={<MdMoreVert />}
						  variant="solid"
						  backgroundColor={'yellowgreen'}
						  size="md"
						  m={2}
						  mr={4}
						/>
						<MenuList>
						  <MenuItem onClick={() => handleViewQrCode(person)}>
							<Button variant="ghost" colorScheme="red">
							  <MdQrCode color="red" />
							</Button>
							Create QR
						  </MenuItem>
						  <MenuItem onClick={() => handleEdit(person)}>
							<Button variant="ghost" colorScheme="blue">
							  <MdEdit color="blue" />
							</Button>
							Edit Property
						  </MenuItem>
						  <MenuItem onClick={handleNavigateToClient}>
							<Button variant="ghost" colorScheme="red">
							  <MdPeople color="red" />
							</Button>
							Go To Clients Page
						  </MenuItem>
						</MenuList>
					  </Menu>
					  <Button variant={'solid'} colorScheme={'blue'}>
						<AccordionIcon />
					  </Button>
					</AccordionButton>
					<AccordionPanel pb={4}>
					  <Box>
						<TableContainer>
						  <Table>
							<Thead>
							  <Tr>
								{Object.keys(person).map(
								  (key) =>
									isThisFieldRequiredToNotShow(key) && <Th key={key}>{key}</Th>
								)}
							  </Tr>
							</Thead>
							<Tbody>
							  <Tr>
								{Object.keys(person).map(
								  (key) =>
									isThisFieldRequiredToNotShow(key) && <Td key={key}>{person[key]}</Td>
								)}
							  </Tr>
							</Tbody>
						  </Table>
						</TableContainer>
					  </Box>
					</AccordionPanel>
				  </AccordionItem>
				))}
			</Accordion>
		  </Box>
		  <Modal isOpen={isAddRequired} onClose={handleCloseModal} size={'lg'}>
			<ModalOverlay />
			<ModalContent>
			  <ModalHeader>Property fields</ModalHeader>
			  <ModalCloseButton />
			  <ModalBody>
				<Box>
				  {fields.map((field, index) => (
					<Box key={index} mb={2}>
					  <FormControl isInvalid={proprtyErrors[field.label]}>
						<FormLabel>{field.label}</FormLabel>
						<Flex alignItems={'center'} justifyContent={'space-between'}>
						  <Input
							type={field.type}
							value={field.value}
							onChange={(e) =>
							  handleFieldChange(index, field.label, e.target.value)
							}
						  />
						  <IconButton
							icon={<DeleteIcon />}
							color={'red'}
							ml={2}
							aria-label="Delete Field"
							onClick={() => handleDeleteField(field.id)}
						  />
						</Flex>
						<FormErrorMessage>{proprtyErrors[field.label]}</FormErrorMessage>
					  </FormControl>
					</Box>
				  ))}
				  <Button
					width="full"
					colorScheme="green"
					leftIcon={<Icon as={MdAdd} />}
					onClick={() => setIsCustomFieldRequired(true)}
					mt={4}
				  >
					Add Field
				  </Button>
				</Box>
				<ModalFooter pr={0}>
				  <Flex gap={3} alignItems={'center'} justifyItems={'flex-end'}>
					<Button colorScheme="red" onClick={handleCloseModal}>
					  Close
					</Button>
					<Button colorScheme="blue" onClick={handleSubmitPropertyForm}>
					  Submit
					</Button>
				  </Flex>
				</ModalFooter>
			  </ModalBody>
			</ModalContent>
		  </Modal>
		  <Modal isOpen={isEditRequired} onClose={handleCloseEditModal} size={'lg'}>
			<ModalOverlay />
			<ModalContent>
			  <ModalHeader>Edit Property</ModalHeader>
			  <ModalCloseButton />
			  <ModalBody>
				<Box>
				  {editFields.map((field, index) => (
					<Box key={index} mb={2}>
					  <FormControl>
						<FormLabel>{field.name}</FormLabel>
						<Input
						  type={field.type}
						  value={field.value}
						  onChange={(e) => handleEditFieldChange(index, field.name, e.target.value)}
						/>
					  </FormControl>
					</Box>
				  ))}
				</Box>
				<ModalFooter pr={0}>
				  <Flex gap={3} alignItems={'center'} justifyItems={'flex-end'}>
					<Button colorScheme="red" onClick={handleCloseEditModal}>
					  Close
					</Button>
					<Button colorScheme="blue" onClick={handleEditPropertyForm}>
					  Submit
					</Button>
				  </Flex>
				</ModalFooter>
			  </ModalBody>
			</ModalContent>
		  </Modal>
		  <Modal isOpen={isCustomFieldRequired} onClose={handleCloseCustomModal} size={'md'}>
			<ModalOverlay />
			<ModalContent>
			  <ModalHeader>Add Custom field</ModalHeader>
			  <ModalCloseButton />
			  <ModalBody>
				<Box w={'100%'}>
				  <FormControl isInvalid={errors.label} mb={2}>
					<FormLabel>Label</FormLabel>
					<Input
					  required
					  value={customField.label}
					  onChange={(e) => handleChangeCustomField('label', e.target.value)}
					/>
					<FormErrorMessage>{errors.label}</FormErrorMessage>
				  </FormControl>
				  <FormControl isInvalid={errors.name}>
					<FormLabel>Name</FormLabel>
					<Input
					  required
					  value={customField.name}
					  onChange={(e) => handleChangeCustomField('name', e.target.value)}
					/>
					<FormErrorMessage>{errors.name}</FormErrorMessage>
				  </FormControl>
				  <FormControl mb={2}>
					<FormLabel>Value</FormLabel>
					<Input
					  required
					  value={customField.value}
					  onChange={(e) => handleChangeCustomField('value', e.target.value)}
					/>
				  </FormControl>
				  <FormControl isInvalid={errors.type} mb={2}>
					<FormLabel>Type</FormLabel>
					<Input
					  required
					  value={customField.type}
					  onChange={(e) => handleChangeCustomField('type', e.target.value)}
					/>
					<FormErrorMessage>{errors.type}</FormErrorMessage>
				  </FormControl>
				</Box>
				<ModalFooter pr={0}>
				  <Flex gap={3} alignItems={'center'} justifyItems={'flex-end'}>
					<Button colorScheme="red" onClick={handleCloseCustomModal}>
					  Close
					</Button>
					<Button colorScheme="blue" onClick={handleAddField}>
					  Add
					</Button>
				  </Flex>
				</ModalFooter>
			  </ModalBody>
			</ModalContent>
		  </Modal>
  
		  <Modal isOpen={requireQrCode}>
			<ModalOverlay />
			<ModalContent>
			  <ModalHeader textAlign={'center'}>QR code</ModalHeader>
			  <ModalBody>
				<Flex alignItems={'center'} justifyContent={'center'} ref={qrCodeRef}>
				  <QRCode
					value={`${window.origin}/#/validate/${
					  JSON.parse(localStorage.getItem('logged_in_user')).id
					}/${
					  JSON.parse(localStorage.getItem('logged_in_user')).accCode
					}/${house.generatedcode}/${
					  JSON.parse(localStorage.getItem('logged_in_user')).email
					}`}
				  />
				</Flex>
			  </ModalBody>
			  <ModalFooter>
				<IconButton
				  onClick={handleDownloadQrCode}
				  aria-label="Download QR Code"
				  icon={<DownloadIcon />}
				  colorScheme="blue"
				  mr={3}
				/>
				<Button variant="solid" colorScheme="red" onClick={handleQrCodeClose}>
				  Close
				</Button>
			  </ModalFooter>
			</ModalContent>
		  </Modal>
		</Box>
	  </Box>
	);
  }
  