import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Box, Code, Spinner, Text, useToast, FormControl, VStack, FormLabel, Input, FormErrorMessage } from "@chakra-ui/react";
import {
  usePlaidLink,
} from 'react-plaid-link';
import axios from "axios";
import { useLocation } from "react-router-dom";

function Plaidlink() {
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userForm, setUserForm] = useState({ name: '', email: '', number: '', address: '' });
  const [errors, setErrors] = useState({});
  const [clientcode, setclientcode] = useState("");
  const [accCode, setaccCode] = useState("");

  

  const toast = useToast()
  const location = useLocation();

  const getUrlParams = () => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const agency = params.get('agency');
    const clientcode = params.get('clientcode');
    const accCode = params.get('accCode');

    return { id, agency, clientcode , accCode };
  };

  useEffect(() => {
    const { id, agency, clientcode , accCode } = getUrlParams();
    console.log({ id, agency, clientcode , accCode });
    setclientcode(clientcode)
    setaccCode(accCode)

    // You can use these parameters as needed
  }, [location]);


  const config = useMemo(() => ({
    onSuccess: async () => {
      // Handle successful authentication
      const { name, address, email, number } = JSON.parse(localStorage.getItem('user'));
      setLoading(true)
      const getAccessToken = await axios.post(`${process.env.REACT_APP_BACKEND}/plaidClients/addplaid`, {
        name: name, email, number, address , clientcode , accCode
      })

      if (getAccessToken.status === 201) {
        setLoading(false);
        toast({
          title: 'Details Submitted',
          description: 'Plaid details submitted successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right'
        })
        localStorage.removeItem('user')
        handleClear()
      }
    },
    onExit: (err, metadata) => {
      console.log('Error:', err);
    },
    onEvent: (eventName, metadata) => {
      // Handle link event
      console.log('Event Name:', eventName);
    },
    token: token,
  }), [userForm, token, setLoading]);

  const { open, ready } = usePlaidLink(config);

  const handleClear = () => {
    setUserForm({ name: '', email: '', number: '', address: '' });
  };

  // Creates a Link token
  const createLinkToken = React.useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem("link_token");
      setToken(linkToken);
    } else {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/plaid/createLinkToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "userId": "user123"
        })
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error('Failed to create link token');
      } else {
        setToken(data.link_token);
      }

      localStorage.setItem("link_token", data.link_token);
    }
  }, [setToken]);

  useEffect(() => {
    if (token == null) {
      createLinkToken();
    }
  }, [token, ready, open]);

  // Function to validate form fields
  const validateUserForm = () => {
    const newErrors = {};
    const { name, email, number, address } = userForm;
    if (!name) {
      newErrors.name = "Name is required";
    }
    if (!email) {
      newErrors.email = "Email is required";
    }
    if (!number) {
      newErrors.number = "Number is required";
    }
    if (!address) {
      newErrors.address = "Address is required";
    }
    return newErrors;
  };

  const handleChangeField = (field, fieldValue) => {
    const newErrors = { ...errors };
    delete newErrors[field];

    setUserForm({ ...userForm, [field]: fieldValue });
    setErrors(newErrors);
  };

  return (
    <Box textAlign={'center'} display={'flex'} flexDirection={'column'} gap={'8px'} alignContent={'center'}>
      {loading && <Spinner thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl' />}

      <Text fontSize={'xl'} marginBottom={'10px'}>Please fill out your information and a Screening</Text>
      <VStack spacing={4} alignItems="start">
        <FormControl isInvalid={errors.name} mb={2}>
          <FormLabel>Name</FormLabel>
          <Input
            value={userForm.name}
            onChange={(e) => handleChangeField("name", e.target.value)}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.email} mb={2}>
          <FormLabel>Email</FormLabel>
          <Input
            value={userForm.email}
            onChange={(e) => handleChangeField("email", e.target.value)}
          />
          <FormErrorMessage>{errors.email}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.address} mb={2}>
          <FormLabel>Address</FormLabel>
          <Input
            value={userForm.address}
            onChange={(e) => handleChangeField("address", e.target.value)}
          />
          <FormErrorMessage>{errors.address}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.address} mb={2}>
          <FormLabel>Number</FormLabel>
          <Input
            value={userForm.number}
            onChange={(e) => handleChangeField("number", e.target.value)}
          />
          <FormErrorMessage>{errors.number}</FormErrorMessage>
        </FormControl>
      </VStack>

      <Button onClick={() => {
        if (Object.keys(validateUserForm()).length > 0) {
          setErrors(validateUserForm());
          return;
        }
        localStorage.setItem('user', JSON.stringify(userForm))
        open()
      }} disabled={!ready} colorScheme="blue">
        Link account
      </Button>

      {!loading && data != null && (
        <Box mt={4}>
          {Object.entries(data).map((entry, i) => (
            <Code key={i} display="block" whiteSpace="pre">
              {JSON.stringify(entry[1], null, 2)}
            </Code>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default Plaidlink;