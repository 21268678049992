import React from 'react';
import {
	Box,
	Text,
	Button,
	useDisclosure,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	IconButton,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Grid,
	GridItem,
	Heading,
	InputGroup,
	InputLeftAddon,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Radio,
	RadioGroup,
	StatLabel,
	Stat,
	StatNumber,
	Spinner,
	Card,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	FormControl,
	FormLabel,
	useColorModeValue,Stack

} from '@chakra-ui/react';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { MdDelete } from 'react-icons/md';
import { BsFillPeopleFill } from 'react-icons/bs';
import { HiDocumentReport } from 'react-icons/hi';
import { FaLocationDot } from 'react-icons/fa6';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import { MdPersonPin } from 'react-icons/md';
import { IoSearch } from 'react-icons/io5';
import { IoFilterSharp } from 'react-icons/io5';
import { BsSortDown } from 'react-icons/bs';
import { capitalize, sum } from 'lodash';
import axios from 'axios';

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export default function Screening() {
	const [selectedPerson, setSelectedPerson] = useState(null);
	const [finresult, setResult] = useState(null);
	const [output, setoutput] = useState(null);

	const [peopleData, setPeopleData] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [showFilter, setShowFilter] = useState(false);
	const [showSort, setShowSort] = useState(false);

	const [dateFilter, setDateFilter] = useState('A');
	const [sortBy, setSortBy] = useState('Group');
	const [sortOrder, setSortOrder] = useState('asc');
	const [searchTerm, setSerachTerm] = useState('');
	const [agentemail, setagentemail] = useState('');

	const [showReport, setShowReport] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [loading, setLoading] = useState(false);
	const [sliderValue, setSliderValue] = React.useState(0);

	const bg = useColorModeValue('white', 'gray.800');

	const cancelRef = useRef();

	useEffect(() => {
		const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user'));
		const userEmail = loggedInUser?.email;
		const accCode = loggedInUser?.accCode;

		if (userEmail) {
			setagentemail(userEmail);
			setLoading(true);

			axios
				.post(`${process.env.REACT_APP_BACKEND}/plaidClients/listbycode`, {
					accCode: accCode,
				})
				.then((res) => {
					setLoading(false);
					setPeopleData(res.data.plaidClients);
					console.log(res.data.plaidClients);
				})
				.catch((err) => {
					setLoading(false);
					console.error(err);
				});
		}
	}, []);

	const parsedDebtData = useCallback((debtsData) => {
		let totalDebt = 0;
		const debtDetails = [];

		if (Array.isArray(debtsData)) {
			debtsData.forEach((client) => {
				if (client?.amount && client?.amount > 0) {
					totalDebt += client.amount;
					debtDetails.push({
						amount: client.amount,
						year: new Date(client.date).getFullYear(),
						month: months[new Date(client.date).getMonth()],
					});
				}
			});
		}

		return { totalDebt, debtDetails };
	}, []);


	const parsedIncomeData = useCallback((income, assets) => {
		let totalIncome = 0;
		const incomeDetails = [];

		if (Array.isArray(income)) {
			income.forEach((client) => {
				if (client?.amount && client?.amount < 0) {
					totalIncome -= client.amount;
					incomeDetails.push({
						amount: client.amount,
						year: new Date(client.date).getFullYear(),
						month: months[new Date(client.date).getMonth()],
					});
				}
			});
		}

		return {
			assets: assets,
			totalIncome: totalIncome,
			monthlyIncome: totalIncome,
			incomeDetails,
		};
	}, []);



	const handleClick = (person) => {
		setSelectedPerson(person);
	};

	const handleOpen = (person) => {
		setSelectedPerson(person);
		onOpen();
	};

	const handleRemove = () => {
		const updatedPeople = peopleData.filter(
			(person) => JSON.stringify(person) !== JSON.stringify(selectedPerson)
		);
		setSelectedPerson(null);
		setResult(null);
		setPeopleData(updatedPeople);
		onClose();
	};

	useEffect(() => {
		const fetchResult = async () => {
			if (selectedPerson) {
				try {
					const message = `What is the conclusion for this info person's income is ${parsedIncomeData(
						selectedPerson.income
					).totalIncome} and person's debt is ${parseFloat(
						parsedDebtData(selectedPerson.debts).totalDebt
					).toFixed(2)}?`;
					const requestOptions = {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ message: message }),
					};
					const response = await fetch(
						`${process.env.REACT_APP_BACKEND}/gpt/chatgpt`,
						requestOptions
					);
					const data = await response.json();
					setResult(data.response);
				} catch (error) {
					console.error('Error fetching result:', error);
				}
			}
		};

		fetchResult();
	}, [selectedPerson]);

	useEffect(() => {
		if (searchTerm.length <= 1) {
			setPeopleData(peopleData);
		}
		else {
			const data = peopleData
				.filter((p) =>
					searchTerm
						? String(p.name).toLowerCase().includes(searchTerm.toLowerCase())
						: p
				)
				.map((person) => ({
					...person,
					isExpanded: false,
				}));
			setPeopleData(data);
		}

	}, [searchTerm]);

	const handleAccordionToggle = (personId) => {
		setPeopleData((prevPeopleData) =>
			prevPeopleData.map((person) =>
				person.id === personId
					? { ...person, isExpanded: !person.isExpanded }
					: person
			)
		);
	};

	const handleFilterApply = () => {
		setShowFilter(false);
	};

	const handleSort = () => {
		setShowSort(false);
	};

	useEffect(() => {
		const handleResize = () => {
			const isMobileDevice = window.innerWidth <= 768;
			setIsMobile(isMobileDevice);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleSearchChange = useCallback((e) => {
		setSerachTerm(e.target.value);
		if (e.target.value <= 0) {
			setPeopleData(peopleData);
		}
		else {
			const data = peopleData
				.filter((p) =>
					searchTerm
						? String(p.name).toLowerCase().includes(searchTerm.toLowerCase())
						: p
				)
				.map((person) => ({
					...person,
					isExpanded: false,
				}));
			setPeopleData(data);
		}
	}, []);

	const handleShowReport = useCallback((person) => {
		setSelectedPerson(person);
		setShowReport(true);
	}, []);

	const renderTable = (months) => {
		return (
			<TableContainer>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Month</Th>
							<Th>Income</Th>
							<Th>Expenses</Th>
						</Tr>
					</Thead>
					<Tbody>
						{months.map((monthData) => (
							<Tr key={monthData.month}>
								<Td>{monthData.month}</Td>
								<Td>${monthData.income.toFixed(2)}</Td>
								<Td>${monthData.expenses.toFixed(2)}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<Box
			p={8}
			bg='white'
			borderRadius='lg'
			boxShadow='md'
			minHeight={'100vh'}
			width={'100%'}
			maxWidth='100%'
			margin='0'
		>
			{/* modal showing filtering options */}
			<Modal isOpen={showFilter} onClose={() => setShowFilter(false)} size={isMobile ? "sm" : "md"}>
				<ModalOverlay />
				<ModalContent bg={bg}>
					<ModalHeader>Filter Options</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={6}>
						<FormControl mb={4}>
							<FormLabel>Select category</FormLabel>
							<Select
								placeholder='Select category'
								onChange={(e) => setDateFilter(e.target.value)}
							>
								<option value='A'>Income</option>
								<option value='B'>Assets</option>
								<option value='C'>Debts</option>
							</Select>
						</FormControl>

						<Text fontWeight='semibold' mb={2}>
							Set a value to filter the selected category
						</Text>
						<Box mb={4}>
							<Slider
								aria-label='slider-ex-1'
								defaultValue={0}
								min={0}
								max={1000000}
								step={10000}
								onChange={(val) => setSliderValue(val)}
							>
								<SliderTrack bg='blue.100'>
									<SliderFilledTrack bg='blue.500' />
								</SliderTrack>
								<SliderThumb boxSize={6} />
							</Slider>
							<Text mt={2}>Value: {sliderValue}</Text>
						</Box>

						<FormControl mb={4}>
							<FormLabel>Condition</FormLabel>
							<Select placeholder='greater or lesser'>
								<option value='greater'>Greater</option>
								<option value='lesser'>Lesser</option>
							</Select>
						</FormControl>

						<Button colorScheme='blue' width='full' onClick={handleFilterApply}>
							Apply Filters
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>

			{/* modal showing sorting options */}
			<Modal isOpen={showSort} onClose={() => setShowSort(false)} size={isMobile ? "sm" : "md"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Sort Options</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Select
							placeholder='Sort By'
							mb={4}
							onChange={(e) => setSortBy(e.target.value)}
						>
							<option value='Name'>Income</option>
							<option value='Name'>Assets</option>
							<option value='Name'>Debts</option>
						</Select>
						<RadioGroup
							defaultValue='asc'
							mb={4}
							onChange={(value) => setSortOrder(value)}
						>
							<Stack spacing={4} direction="row">
							<Radio value='asc'>Ascending</Radio>
							<Radio value='desc'>Descending</Radio>
							</Stack>
						</RadioGroup>
						<Button colorScheme='blue' onClick={handleSort}>
							Apply Sorting
						</Button>
					</ModalBody>
				</ModalContent>
			</Modal>

			{selectedPerson && (
				<Modal
					isOpen={showReport}
					onClose={() => setShowReport(false)}
					size={'xl'}
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader
							textAlign={'center'}
							borderBottom={'1px solid lightgray'}
						>
							<Text fontSize={'2xl'}>Screening Report</Text>
						</ModalHeader>
						<ModalBody>
							<Box
								display={'flex'}
								flexDirection={'column'}
								padding={'12px 32px'}
								gap={8}
							>
								<Text
									textAlign={'center'}
									fontSize={'xl'}
									as='b'
									textDecoration={'underline'}
								>
									Personal Details
								</Text>
								<Box borderBottom={'1px solid lightgray'} paddingBottom={'16px'}>
									<Box
										display={isMobile ? 'block' : 'flex'}
										gap={1}
										alignItems={'center'}
									>
										<Box
											bg={'orange'}
											borderRadius={'full'}
											w={10}
											p={2}
											fontWeight={'bold'}
											marginRight={5}
											marginBottom={5}
										>
											{selectedPerson.name.split(' ').map((name) => name[0])}
										</Box>
										<Box m={isMobile ? 0 : 5}>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={'flex-start'}
												gap={2}
											>
												<MdPersonPin size={20} />
												<Text fontSize='md' fontWeight='bold'>
													{selectedPerson.name}
												</Text>
											</Box>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={'flex-start'}
												gap={2}
											>
												<IoMdMail />
												<Text fontSize='md' fontWeight='bold'>
													{selectedPerson.email}
												</Text>
											</Box>
										</Box>
									</Box>
									<Box
										display={'flex'}
										alignItems={'center'}
										justifyContent={'flex-start'}
										gap={2}
									>
										<FaLocationDot />
										<Text fontSize='md' fontWeight='bold'>
											Address:
										</Text>
										{selectedPerson.address}
									</Box>
									<Box
										display={'flex'}
										alignItems={'center'}
										justifyContent={'flex-start'}
										gap={2}
									>
										<FaPhoneAlt />
										<Text fontSize='md' fontWeight='bold'>
											Phone:
										</Text>
										{selectedPerson.number}
									</Box>
								</Box>
								<Text
									textAlign={'center'}
									fontSize={'xl'}
									as='b'
									textDecoration={'underline'}
								>
									Financial Details
								</Text>
								<Box
									display={'grid'}
									gridTemplateColumns={'1fr 1fr'}
									gridTemplateRows={'auto'}
									gap={'8px'}
									paddingBottom={'16px'}
									borderBottom={'1px solid lightgray'}
								>
									<Stat>
										<StatLabel>Income</StatLabel>
										<StatNumber>
											$ {parsedIncomeData(selectedPerson.income).totalIncome}
										</StatNumber>
									</Stat>
									<Stat>
										<StatLabel>Assets</StatLabel>
										<StatNumber>{selectedPerson.assets.assets}</StatNumber>
									</Stat>
									<Stat>
										<StatLabel>Debt</StatLabel>
										<StatNumber>
											${' '}
											{parseFloat(
												parsedDebtData(selectedPerson.debts).totalDebt
											).toFixed(2)}
										</StatNumber>
									</Stat>
									<Stat>
										<StatLabel>Income-Debt Ratio</StatLabel>
										<StatNumber>
											{parseFloat(
												(parsedIncomeData(selectedPerson.income).totalIncome /
													parseFloat(
														parsedDebtData(selectedPerson.debts).totalDebt
													).toFixed(2)) *
												100
											).toFixed(2)}
											%
										</StatNumber>
									</Stat>
								</Box>
								<Text
									textAlign={'center'}
									fontSize={'xl'}
									as='b'
									textDecoration={'underline'}
								>
									AI Results
								</Text>
								<Text fontSize={'l'} textAlign={'justify'}>
									{finresult}
								</Text>
							</Box>
						</ModalBody>
					</ModalContent>
				</Modal>
			)}

			{/* header */}
			<Box
				display={isMobile ? 'block' : 'flex'}
				w={'100%'}
				justifyContent={'center'}
				alignItems={'center'}
			>
				{/* Title */}
				<Heading size={'xl'} m={5} fontFamily={'monospace'} fontWeight={'bolder'}>
					Screening
				</Heading>
				{/* input to search */}
				<InputGroup
					h={10}
					marginTop={5}
					marginRight={10}
					marginLeft={isMobile ? 0 : 3}
					w={isMobile ? '100%' : '50%'}
				>
					<InputLeftAddon>
						<IoSearch />
					</InputLeftAddon>
					<Input
						placeholder='Search'
						w={'100%'}
						border={'1px solid blue'}
						onChange={handleSearchChange}
					></Input>
				</InputGroup>

				{/* group of buttons showing filter and sort modals */}
				<Box mb={4} textAlign='center' margin={5} display={'flex'} marginTop={12}>
					<Box marginRight={3}>
						<Button onClick={() => setShowFilter(true)} colorScheme='blue' mr={2}>
							<IoFilterSharp />
						</Button>
						<Text>Filter</Text>
					</Box>
					<Box>
						<Button onClick={() => setShowSort(true)} colorScheme='blue'>
							<BsSortDown />
						</Button>
						<Text>Sort</Text>
					</Box>
				</Box>
			</Box>
			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Person
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure you want to delete {selectedPerson?.name}?
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button colorScheme='red' onClick={handleRemove} ml={3}>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Box
				display={isMobile ? 'block' : 'grid'}
				gridTemplateColumns='repeat(repeat(1, 1fr))'
				gap={4}
				overflow-x='auto'
				w={isMobile ? '100%' : '100%'}
				padding={'0 auto'}
			>
				{loading && (
					<Box display={'flex'} justifyContent={'center'} width={'100%'}>
						<Spinner
							textAlign={'center'}
							thickness='4px'
							speed='0.65s'
							emptyColor='gray.200'
							color='blue.500'
							size='xl'
						/>
					</Box>
				)}
				{peopleData.length && !loading
					? peopleData.map((p, index) => {
						const income = parsedIncomeData(p.trans?.added || [], p.assets.assets);
						return (
							<GridItem
								key={`screening-item-${index}`}
								w={isMobile ? '100%' : '100%'}
								marginBottom={isMobile ? 5 : 0}
							>
								<Card p={4} bg={'gray.50'} borderRadius='lg'>
									<Accordion allowToggle>
										<AccordionItem>
											<h2>
												<AccordionButton onClick={() => handleAccordionToggle(p.id)}>
													<Box as='span' flex='1' textAlign='left'>
														<Text as={'b'} fontSize='2xl'>
															{p.name}
														</Text>
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Box display={isMobile ? 'block' : 'flex'} gap={1} alignItems={'center'}>
													<Box
														bg={'orange'}
														borderRadius={'full'}
														w={10}
														p={2}
														fontWeight={'bold'}
														marginRight={5}
														marginBottom={5}
													>
														{p.name.split(' ').map((l) => l[0])}
													</Box>
													<Box m={isMobile ? 0 : 5}>
														<Box
															display={'flex'}
															alignItems={'center'}
															justifyContent={'flex-start'}
															gap={2}
														>
															<MdPersonPin size={20} />
															<Text fontSize='md' fontWeight='bold'>
																{p.name}
															</Text>
														</Box>
														<Box
															display={'flex'}
															alignItems={'center'}
															justifyContent={'flex-start'}
															gap={2}
														>
															<IoMdMail />
															<Text fontSize='md' fontWeight='bold'>
																{p.email}
															</Text>
														</Box>
													</Box>
												</Box>
												<Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={2}>
													<FaLocationDot />
													<Text fontSize='md' fontWeight='bold'>
														Address:
													</Text>
													{p.address}
												</Box>
												<Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={2}>
													<FaPhoneAlt />
													<Text fontSize='md' fontWeight='bold'>
														Phone:
													</Text>
													{p.number}
												</Box>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
									<TableContainer>
										<Table>
											<Thead>
												<Tr>
													<Th>Income</Th>
													<Th>Debts</Th>
												</Tr>
											</Thead>
											<Tbody>
												<Tr>
													<Td width={'33%'} verticalAlign={'top'}>
														<Accordion allowToggle>
															<AccordionItem>
																<h4>
																	<AccordionButton>
																		<Box as='b' flex='1' textAlign='left'>
																			Total income: $ {income.totalIncome}
																		</Box>
																		<AccordionIcon />
																	</AccordionButton>
																</h4>
																<AccordionPanel>
																	<Box
																		display={'flex'}
																		flexDirection={'column'}
																		gap={'2px'}
																		alignItems={'flex-start'}
																		justifyContent={'center'}
																	>
																		{income.incomeDetails.map((detail, index) => (
																			<Text
																				as={'div'}
																				width={'100%'}
																				display={'flex'}
																				justifyContent={'space-between'}
																				key={index}
																			>
																				<Text as={'b'}>
																					{detail.month} {detail.year} :&nbsp;
																				</Text>
																				$ {detail.amount}
																			</Text>
																		))}
																	</Box>
																</AccordionPanel>
															</AccordionItem>
														</Accordion>
													</Td>
													<Td verticalAlign={'top'} width={'33%'}>
														<Accordion allowToggle>
															<AccordionItem>
																<h4>
																	<AccordionButton>
																		<Box as='b' flex='1' textAlign='left'>
																			Total Debts :
																		</Box>
																		$ {parseFloat(parsedDebtData(p.trans?.added || []).totalDebt).toFixed(2)}
																		<AccordionIcon />
																	</AccordionButton>
																</h4>
																<AccordionPanel>
																	<Box
																		display={'flex'}
																		flexDirection={'column'}
																		gap={'2px'}
																		alignItems={'flex-start'}
																		justifyContent={'center'}
																	>
																		{parsedDebtData(p.trans?.added || []).debtDetails.map((detail, index) => (
																			<Text
																				as={'div'}
																				width={'100%'}
																				display={'flex'}
																				justifyContent={'space-between'}
																				key={index}
																			>
																				<Text as={'b'}>
																					{detail.month} {detail.year} :&nbsp;
																				</Text>
																				$ {detail.amount}
																			</Text>
																		))}
																	</Box>
																</AccordionPanel>
															</AccordionItem>
														</Accordion>
													</Td>
												</Tr>
											</Tbody>
										</Table>
									</TableContainer>
									<Box display='flex' justifyContent='space-between' mt={4}>
										<IconButton icon={<HiDocumentReport />} colorScheme='blue' onClick={() => handleShowReport(p)} />
										<IconButton icon={<MdDelete />} colorScheme='red' onClick={onOpen} />
									</Box>
								</Card>
							</GridItem>
						);
					})
					: !loading && (
						<Text fontSize={'2xl'} textAlign={'center'}>
							No results found for "{searchTerm}".
						</Text>
					)}
			</Box>
			<Box mt={8}>
				{output && (
					<Accordion allowToggle>
						{output.map((yearData) => (
							<AccordionItem key={yearData.year}>
								<AccordionButton>
									<Box flex='1' textAlign='left'>
										<Text fontSize='xl' fontWeight='bold'>
											{yearData.year}
										</Text>
									</Box>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel pb={4}>{renderTable(yearData.months)}</AccordionPanel>
							</AccordionItem>
						))}
					</Accordion>
				)}
			</Box>
		</Box>
	);
}
