import React, { useCallback, useState } from 'react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
	Input,
	Select,
	Flex,
	Box,
	Heading,
	Divider,
} from '@chakra-ui/react'

const FilterModal = ({ isOpen, onClose, onFilter }) => {
	const [filterTerm, setFilterTerm] = useState('')
	const [filterOption, setFilterOption] = useState('')

	const handleFilter = useCallback(() => {
		onFilter({
			term: filterTerm,
			option: filterOption,
		})
		onClose()
	}, [filterOption, filterTerm])

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading size='md'>Filter team members</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex mb={4}>
						<Box mr={4}>
							<Heading size='sm' mb={2}>
								Filter Option
							</Heading>
							<Select
								placeholder='Select Filter option'
								value={filterOption}
								onChange={(e) => setFilterOption(e.target.value)}
							>
								<option value='username'>Full Name</option>
								<option value='role'>Account Type</option>
								<option value='email'>Email</option>
							</Select>
						</Box>
						{filterOption === 'role' ? (
							<Box>
								<Heading size='sm' mb={2}>
									Account Type
								</Heading>
								<Flex>
									<Select
										placeholder='Select Account Type'
										value={filterTerm}
										onChange={(e) => setFilterTerm(e.target.value)}
									>
										<option value='admin'>Admin</option>
										<option value='member'>Member</option>
									</Select>
								</Flex>
							</Box>
						) : (
							<Box>
								<Heading size='sm' mb={2}>
									Enter search term
								</Heading>
								<Flex>
									<Input
										placeholder='search term to filter'
										onChange={(e) => setFilterTerm(e.target.value)}
									/>
								</Flex>
							</Box>
						)}
					</Flex>
				</ModalBody>
				<ModalFooter>
					<Button
						colorScheme='red'
						mr={3}
						onClick={() => {
							setFilterOption('')
							setFilterTerm('')
						}}
					>
						Clear Filter
					</Button>
					<Button colorScheme='telegram' mr={3} onClick={handleFilter}>
						Apply Filter
					</Button>
					<Button variant='ghost' onClick={onClose}>
						Cancel
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default FilterModal
