import React, { useState, useEffect } from 'react'
import { Box, Text, useColorModeValue, Flex } from '@chakra-ui/react'

import './component/deploy-style.css'

import axios from 'axios'
import ViewTable from './component/ViewClientTable'

export default function Deploy() {
	const [apps, setApps] = useState([])
	const [tabIndex, setTabIndex] = useState(0)
	const { v4: uuidv4 } = require('uuid')

	const obj = {
		examplelist: 'value',

		examplelistrow2: 'value2',
	}
	const listItems = ['Item 1', 'Item 2', 'Item 3', 'Item 4']
	const initial_apps = [
		{
			id: '1',
			app_name: 'texas call center',
			app_url: 'texascallcenter.com',
			app_number: '123456',
			version: '1.0.0',
			isDeleted: false,
		},
		{
			id: '2',
			app_name: 'texas call center 2',
			app_url: 'texascallcenter_2.com',
			app_number: '123456',
			version: '1.0.0',
			isDeleted: false,
		},
		{
			id: '3',
			app_name: 'texas call center 3',
			app_url: 'texascallcenter_3.com',
			app_number: '123456',
			version: '1.0.0',
			isDeleted: false,
		},
	]

	const [refreshTable, setRefreshTable] = useState(true)

	const [updatedApps, setUpdatedApps] = useState([])
	
	useEffect(() => {
		//fetchApps();
		if (!process.env.REACT_APP_DEPLOY_MOCK_DATA) {
			//fetchApps();
		} else {
			if (updatedApps && updatedApps.length > 0) {
				setApps(initial_apps)
			} else {
				setApps(initial_apps)
			}
		}
	}, [updatedApps])

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      p={3}
    >
      <Box
        width="100%"
      >
        <Text
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
          color="black"
          mb={8}
        >
          Clients
        </Text>
        <ViewTable initialApps={initial_apps} />
      </Box>
    </Flex>
  );
}
