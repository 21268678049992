// Importing necessary components and hooks
import {
	Box,
	Text,
	VStack,
	HStack,
	Button,
	Input,
	Flex,
	useColorModeValue,
	Icon,
	FormControl,
	FormLabel,
	FormErrorMessage,
	useToast,
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { MdPerson } from 'react-icons/md'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { MdOutlineLocationSearching } from 'react-icons/md'
import { FaEye } from 'react-icons/fa'

// Functional component for the user form
export default function Ophouseform() {
	const { email, formCode } = useParams()
	const toast = useToast()
	const api = process.env.REACT_APP_API_URL
	const textColor = useColorModeValue('navy.700', 'white')

	const [userForm, setUserForm] = useState({
		name: '',
		email: '',
		number: '',
		address: '',
		password: '',
		lastactive: new Date(),
	})
	const [agentInfo, setAgentInfo] = useState(null)
	const [agentName, setAgentName] = useState('')
	const [agentCode, setAgentCode] = useState('')
	const [agentemail, setAgentEmail] = useState('')
	const [agentLess, setAgentLess] = useState('false')
	const [propertyname, setPropertyname] = useState('false')
	const [listname, setlistingname] = useState('')
	const [errors, setErrors] = useState({})
	const [showPassword, setShowPassword] = useState(false)
	const [isWorkingWithRealtor, setIsWorkingWithRealtor] = useState(null)

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)

	useEffect(() => {
		const pathSegments = location.pathname.split('/')
		const agentId = pathSegments[3]
		const agentcode = pathSegments[5]

		setAgentCode(localStorage.getItem('accCode'))
		setAgentEmail(localStorage.getItem('email'))
		setlistingname(localStorage.getItem('listingname'))
		const fetchAgent = async () => {
			try {
				const response = await axios.get(`${api}/agents/byemail/${email}`)
				setAgentInfo(response.data.agent)
				try {
					const property = await axios.get(`${api}/property/bycode/${formCode}`)
					setPropertyname(property.data)
				} catch (error) {
					console.log('Error fetching property info:', error)
				}
			} catch (error) {
				console.error('Error fetching agent info:', error)
			}
		}
		fetchAgent()
	}, [location.pathname, api, toast, email, formCode])

	const validateUserForm = () => {
		const newErrors = {}
		const { name, email, number, address } = userForm
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		const phoneRegex = /^\d{10}$/
		
		if (!name) {
			newErrors.name = 'Name is required'
		}
		if (!email) {
			newErrors.email = 'Email is required'
		} else if (!emailRegex.test(email)) {
			newErrors.email = 'Invalid email format'
		}
		if (!number) {
			newErrors.number = 'Phone number is required'
		} else if (!phoneRegex.test(number)) {
			newErrors.number = 'Invalid phone number format'
		}
		if (!address) {
			newErrors.address = 'Address is required'
		}
		return newErrors
	}

	const handleSubmit = async () => {
		const validationErrors = validateUserForm()
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors)
			return
		}
		try {
			if (agentName.length > 0) {
				setAgentLess('false')
			} else {
				setAgentLess('true')
			}
			const newUser = {
				...userForm,
				agentemail: agentemail,
				agentCode: agentCode,
				agentless: agentLess,
				propertys: [propertyname.id],
				listname: propertyname.name,
				lastactive: new Date(),
			}
			if (agentCode) {
				const response = await axios.post(`${api}/clients/create`, newUser)
				setUserForm({
					name: '',
					email: '',
					number: '',
					address: '',
					password: '',
				})
			}
		} catch (error) {
			console.error('Error submitting form:', error)
		}
	}

	const handleClear = () => {
		setUserForm({ name: '', email: '', number: '', address: '', password: '' })
	}

	const handleChangeField = (field, fieldValue) => {
		if (Object.keys(errors).length > 0) {
			delete errors[field]
			setErrors(errors)
		}
		setUserForm({ ...userForm, [field]: fieldValue })
	}

	const handleChangeAgent = (e) => {
		setAgentName(e.target.value)
	}

	const searchAgent = async () => {
		try {
			const nameA = agentName.split(' ').join('%20')
			const response = await axios.get(`${api}/agents/agentName/${nameA}`, {
				headers: { 'Content-Type': 'application/json' },
			})
			setAgentInfo(response.data.agent)
		} catch (error) {
			setAgentInfo(null)
			console.error('Error searching agent:', error)
		}
	}

	return (
		<Box padding='40px' p={2} background={'#fff'} height={'100vh'} textAlign={'center'}>
			<HStack spacing={8} alignItems='center' border='1px solid #ECECEC' borderRadius='10px' p={3} height={'80vh'} width={'fit-content'} background={'#fff'}>
				<Flex
					zIndex='2'
					direction='column'
					w={{ base: '100%', md: '420px' }}
					maxW='100%'
					background='transparent'
					borderRadius='15px'
					mx={{ base: 'auto', lg: 'unset' }}
					me='auto'
					mb={{ base: '20px', md: 'auto' }}
				>
					<Text fontSize='2xl' fontWeight='bold' mb={4} textAlign={'center'}>
						Submit your Info
					</Text>
					{agentInfo && agentInfo.email && (
						<Flex alignItems={'center'} mb={4}>
							<Icon as={MdPerson} boxSize={6} mr={1} color={'blue'} />
							<Text fontSize='xl' fontWeight='bold'>
								Agent Name: {agentInfo.email}
							</Text>
							<Text ml={2} fontSize='xl' casing={'capitalize'}>
								{searchParams.get('name')}
							</Text>
						</Flex>
					)}
					<VStack spacing={4} alignItems='start'>
						<FormControl isInvalid={errors.name} mb={2}>
							<FormLabel>Name</FormLabel>
							<Input value={userForm.name} onChange={(e) => handleChangeField('name', e.target.value)} />
							<FormErrorMessage>{errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={errors.email} mb={2}>
							<FormLabel>Email</FormLabel>
							<Input value={userForm.email} onChange={(e) => handleChangeField('email', e.target.value)} />
							<FormErrorMessage>{errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={errors.address} mb={2}>
							<FormLabel>Address</FormLabel>
							<Input value={userForm.address} onChange={(e) => handleChangeField('address', e.target.value)} />
							<FormErrorMessage>{errors.address}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={errors.number} mb={2}>
							<FormLabel>Phone Number</FormLabel>
							<Input value={userForm.number} onChange={(e) => handleChangeField('number', e.target.value)} />
							<FormErrorMessage>{errors.number}</FormErrorMessage>
						</FormControl>
						<FormControl mb={2}>
							<FormLabel>Are you working with a realtor?</FormLabel>
							<HStack>
								<Button colorScheme={isWorkingWithRealtor === true ? 'green' : 'gray'} onClick={() => setIsWorkingWithRealtor(true)}>
									Yes
								</Button>
								<Button colorScheme={isWorkingWithRealtor === false ? 'red' : 'gray'} onClick={() => setIsWorkingWithRealtor(false)}>
									No
								</Button>
							</HStack>
						</FormControl>
						{isWorkingWithRealtor && (
							<FormControl mb={2} isInvalid={errors.agentName}>
								<FormLabel>Agent Name</FormLabel>
								<Input value={agentName} onChange={handleChangeAgent} />
								<FormErrorMessage>{errors.agentName}</FormErrorMessage>
							</FormControl>
						)}
						<Box width={'100%'}>
							<Button colorScheme='blue' onClick={handleSubmit} width={'100%'} mb={3}>
								Submit
							</Button>
							<Button colorScheme='gray' onClick={handleClear} width={'100%'}>
								Clear
							</Button>
						</Box>
					</VStack>
				</Flex>
			</HStack>
		</Box>
	)
}
