import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, Heading } from '@chakra-ui/layout'
import {
  Avatar,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  useToast,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  PinInput,
  PinInputField
} from '@chakra-ui/react'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import PricingTablePage from '../stripe/pricing';
import { useHistory } from "react-router-dom";
import { BiEdit } from 'react-icons/bi'

export const Account = () => {
  const { user, loginWithPopup , getAccessTokenSilently,isAuthenticated } = useAuth0()

  const [accountData, setAccountData] = useState({})
  const [apiResponseDetails, setApiResponseDetails] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [cellNumber, setCellNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+1')
  const [isVerifyActive, setIsVerifyActive] = useState(false)
  const [otpInput, setOTPInput] = useState('')
  const [openAssignNumber, setOpenAssignNumber] = useState(false)
  const [availableNumbers, setAvailableNumbers] = useState(['20146803151', '20146803152', '20146803153', '20146803154'])
  const [selectedCellNumber, setSelectedCellNumber] = useState('')
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const toast = useToast()
  const history = useHistory();
  //modifiy   subscription: 'standard',

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND}/account/getAccountDetails/${user.sub}`
      )
      .then((res) => {
        setAccountData(res.data.details)
        setApiResponseDetails(res.data.details)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND}/account/addAccountDetails`,
              {
                subscription: 'standard',
                name: user.name,
                address: '',
                companyname: '',
                email: user.email,
                paymentmethod: '',
                settings: {},
                userid: user.sub,
                accCode: user.accCode,
              }
            )
            .then((res) => {
              setAccountData(res.data.details)
              setApiResponseDetails(res.data.details)
            })
            .catch((err) => {
              toast({
                title: 'Error',
                description: 'Internal server error',
                status: 'error',
                duration: 3000,
                position: 'top-right',
              })
            })
        }
      })
  }, [])

  const sanitizeInput = (input) => {
    return input ? input.replace(/[^a-zA-Z0-9_-]/g, '') : '';
  };

  useEffect(() => {
    if (user && isAuthenticated) {

      setEmail(sanitizeInput(user.email));
      setUserId(sanitizeInput(user.sub));

      setDataLoaded(true);
    }
  }, [user, isAuthenticated]);



  const handleOpenAssignNumber = useCallback(() => {
    setOpenModal(true)
  }, [])

  const handleManageBilling = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/create-customer-portal-session`, {
        customerId: userId, // Assuming userId is the Stripe customer ID
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Redirect to the Stripe customer portal
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };

  const handleFieldChange = useCallback((field, value) => {
    setAccountData((prev) => ({ ...prev, [field]: value }))
  }, [])

  const handleUpdateAccountInfo = useCallback(() => {
    const updatedData = {
      "subscription": accountData.subscription,
      "name": accountData.name,
      "address": accountData.address,
      "companyname": accountData.companyname,
      "email": accountData.email,
      "paymentmethod": accountData.paymentmethod,
      "settings": {}
    }
    axios.put(`${process.env.REACT_APP_BACKEND}/account/editAccountDetails/${user.sub}`, updatedData).then((res) => {
      setAccountData(res.data.updatedDetails)
      setApiResponseDetails(res.data.updatedDetails)
      toast({
        title: 'Updated',
        description: 'Details Updated.',
        status: 'success',
        position: 'top-right',
        duration: 3000
      })
    }).catch((err) => {
      toast({
        title: 'Error',
        description: 'Internal server error',
        status: 'error',
        duration: 3000,
        position: 'top-right',
      })
    })
  }, [accountData])



  const [showPricingTable, setShowPricingTable] = useState(false);

  const loadStripePricingTable = useCallback(() => {
    if (!document.querySelector('script[src="https://js.stripe.com/v3/pricing-table.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/pricing-table.js';
      script.async = true;
      script.onload = () => setShowPricingTable(true);
      document.body.appendChild(script);
    } else {
      setShowPricingTable(true);
    }
  }, []);

  const changeplan = useCallback(() => {
    history.push('/subscription');
  }, [history]);


  const handleVerifyNumber = useCallback(() => {
    if (cellNumber && countryCode) {
      axios.post(`${process.env.REACT_APP_BACKEND}/company/sendOTP`, {
        from: `+17208973072`,
        to: `${countryCode}${cellNumber}`,
        userId: user.sub
      }).then((res) => {
        if (res.status === 200) {
          setIsVerifyActive(true)
          toast({
            title: 'SMS sent',
            description: 'OTP sent successfully',
            status: 'success',
            position: 'top-right',
            duration: 3000
          })
        }
      }).catch((err) => {
        toast({
          title: 'Error',
          description: 'Internal server error',
          status: 'error',
          duration: 3000,
          position: 'top-right',
        })
      })
    } else {
      toast({
        title: 'Enter your cell number',
        status: 'error',
        duration: 3000,
        position: 'top-right',
      })
    }
  }, [countryCode, cellNumber])

  const closeVerifyModal = useCallback(() => {
    setOpenModal(false)
    setOTPInput('')
    setCellNumber('')
    setIsVerifyActive(false)
  }, [])

  const handleVerifyOTP = useCallback(() => {
    if (otpInput) {
      axios.post(`${process.env.REACT_APP_BACKEND}/company/verifyOTP`, {
        otp: otpInput,
        userId: user.sub
      }).then((res) => {
        if (res.status === 200) {
          closeVerifyModal()
          setOpenAssignNumber(true)
        }
      }).catch((err) => {
        toast({
          title: 'Error',
          description: 'Internal server error',
          status: 'error',
          duration: 3000,
          position: 'top-right',
        })
      })
    } else {
      toast({
        title: 'Enter valid OTP',
        status: 'error',
        duration: 3000,
        position: 'top-right',
      })
    }
  }, [otpInput])

  const handleAssignNumber = useCallback(() => {
    console.log(selectedCellNumber, 'Number');
    if (selectedCellNumber) {
      axios.post(`${process.env.REACT_APP_BACKEND}/company/assignNumber/${user.sub}`, {
        number: selectedCellNumber
      }).then((res) => {
        if (res.status === 200) {
          setOpenAssignNumber(false)
          setSelectedCellNumber('')
          toast({
            title: 'Number Assigned',
            description: 'Number assigned successfully',
            status: 'success',
            position: 'top-right',
            duration: 3000
          })
        }
      }).catch((err) => {
        toast({
          title: 'Error',
          description: 'Internal server error',
          status: 'error',
          duration: 3000,
          position: 'top-right',
        })
      })
    } else {
      toast({
        title: 'Select number first',
        status: 'error',
        duration: 3000,
        position: 'top-right',
      })
    }
  }, [])

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'48px'}
        gap={'24px'}
        alignItems={'center'}
      >
        <Heading>Account Information</Heading>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={{ base: '100%', md: '80%' }}
          border={'1px solid black'}
          borderRadius={8}
          padding={{ base: '8px', md: '24px' }}
          gap={'16px'}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirection={{ base: 'column-reverse' }}
          >
            <Box display={'flex'} width={'100%'} flexDirection={'column'} gap={'12px'}>
              <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
                <FormLabel flex={1}>Name</FormLabel>
                <Input
                  value={accountData.name || ''}
                  flex={2}
                  onChange={(e) => handleFieldChange('name', e.target.value)}
                />
              </FormControl>
              <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
                <FormLabel flex={1}>Company Name</FormLabel>
                <Input
                  value={accountData.companyname || ''}
                  flex={2}
                  onChange={(e) =>
                    handleFieldChange('companyname', e.target.value)
                  }
                />
              </FormControl>
            </Box>
            <Box flex={1} textAlign={'center'}>
              <Avatar src={user.picture} size={'2xl'} />
              <Text mt={'10px'}>Profile Picture</Text>
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'12px'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
              <FormLabel flex={1}>Address</FormLabel>
              <Textarea
                flex={2}
                placeholder='Your full address'
                value={accountData.address || ''}
                onChange={(e) => handleFieldChange('address', e.target.value)}
              />
            </FormControl>
            <FormControl display="flex" flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
              <FormLabel flex={1}>Subscription</FormLabel>
              <Box flex={2} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'20px'} flexWrap={'wrap'}>
                <Text as={'b'} >{accountData.subscription}</Text>
                <Button
                  minWidth="100px"
                  height={'30px'}
                  colorScheme={'telegram'}
                  borderRadius={8}
                  onClick={handleManageBilling}
                  display="flex" gap="12px" alignItems="center"
                >
                  Billing <BiEdit />
                </Button>
              </Box>
            </FormControl>
            <div id="pricing-container">
              {showPricingTable && (
                <stripe-pricing-table
                pricing-table-id="prctbl_1PHEXD02swyTmtz6y2s3Pi6H" // Replace with your actual Pricing Table ID
                publishable-key="pk_test_51PG0Lh02swyTmtz6bRdQvWaG3EqE1nPnUViVCxgNVCQxVfHCzDyEWgZymQ0H8oX8eZEmfRTI5LQ66oxAQfXFhOu300mIHoRAKV" // Replace with your actual Publishable Key
                customer-email={user.email} // Pass the authenticated user's email
                client-reference-id={user.sub} // Pass the authenticated user's ID
                ></stripe-pricing-table>
              )}
            </div>
            {/* <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
              <FormLabel flex={1}>Payment Method</FormLabel>
              <Input
                value={accountData.paymentmethod || ''}
                flex={2}
                onChange={(e) =>
                  handleFieldChange('paymentmethod', e.target.value)
                }
                isDisabled
              />
            </FormControl> */}
            <FormControl display={'flex'} flexDirection={{ base: 'column', md: 'row' }} gap={'2px'} alignItems={'start'}>
              <FormLabel flex={1}>Email</FormLabel>
              <Input
                value={accountData.email || ''}
                flex={2}
                onChange={(e) => handleFieldChange('email', e.target.value)}
              />
            </FormControl>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'24px'} alignItems={'flex-start'}>
            {/* <FormControl
              flex={2}
              display={'flex'}
              gap={'12px'}
              alignItems={'center'}
            >
              <FormLabel flex={1}>Password</FormLabel>
              <Input
                value={'password'}
                flex={2}
                type='password'
                onChange={(e) => handleFieldChange('password', e.target.value)}
                isDisabled
              />
            </FormControl> */}
            <Flex gap={'8px'} flexDirection={{ base: 'column', lg: 'row' }} maxWidth={'100%'}>
              {/* <Button colorScheme={'telegram'} onClick={() => {
                loginWithPopup({
                  authorizationParams: {
                    screen_hint: 'reset-password'
                  }
                })
              }}>Change Password</Button> */}
              <Button colorScheme={'telegram'} display={'flex'} flexWrap={'wrap'} onClick={handleOpenAssignNumber}>Assign New Number</Button>
            </Flex>
          </Box>
          <Box
            display={'flex'}
            gap={'24px'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            margin={'16px 0'}
            flexWrap={'wrap'}
          >
            <Button maxWidth={'300px'} border={'1px solid gray'} borderRadius={6}>
              Cancel
            </Button>
            <Button
              maxWidth={'300px'}
              colorScheme={'telegram'}
              borderRadius={6}
              isDisabled={JSON.stringify(apiResponseDetails) === JSON.stringify(accountData)}
              onClick={handleUpdateAccountInfo}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        isOpen={openModal}
        onClose={closeVerifyModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'} gap={'16px'} alignItems={'center'} justifyContent={'center'} maxWidth={'100%'}>
            <FormLabel>Enter your number</FormLabel>
            <Flex gap={'10px'} maxWidth={'100%'}>
              <Input width={'70px'} value={countryCode} onChange={(e) => setCountryCode(e.target.value)} />
              <Input width={'300px'} type='tel' value={cellNumber} onChange={(e) => setCellNumber(e.target.value)} />
              <Button onClick={handleVerifyNumber} color={'blue.400'} border={'1px solid black'}>Verify</Button>
            </Flex>
            {isVerifyActive && <>
              <Text>Enter OTP sent to your mobile</Text>
              <HStack justifyContent={'center'}>
                <PinInput value={otpInput} onChange={otp => setOTPInput(otp)}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </>}
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={closeVerifyModal}
            >
              Close
            </Button>
            <Button colorScheme={'telegram'} isDisabled={!isVerifyActive} onClick={handleVerifyOTP}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >
      <Modal
        isOpen={openAssignNumber}
        onClose={() => { setOpenAssignNumber(false) }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign New Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'flex'} flexDirection={'column'} gap={'16px'} alignItems={'center'} justifyContent={'center'} maxWidth={'100%'}>
            <FormLabel>Select new number</FormLabel>
            <Select placeholder={'Select number'} value={selectedCellNumber} onChange={(e) => {
              console.log(e.target.value, 'Selected');
              setSelectedCellNumber(e.target.value)
            }}>
              {availableNumbers.map(number => <option value={number}>{number}</option>)}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={() => { setOpenAssignNumber(false) }}
            >
              Close
            </Button>
            <Button colorScheme={'telegram'} isDisabled={!selectedCellNumber} onClick={handleAssignNumber}>
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal >
    </>
  )
}
