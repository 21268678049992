import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Heading, Text } from '@chakra-ui/react';

const PricingTablePage = () => {
  const { user, isAuthenticated } = useAuth0();
  const [email, setEmail] = useState(null);
  const [userId, setUserId] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Function to sanitize input
  const sanitizeInput = (input) => {
    return input ? input.replace(/[^a-zA-Z0-9_-]/g, '') : '';
  };

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem('logged_in_user'));
    console.log('PricingTablePage loggedInUser');
    console.log(loggedInUser);

    console.log('PricingTablePage isAuthenticated');
    console.log(isAuthenticated);
    if (!loggedInUser) {


      setEmail(sanitizeInput(user.email));
      setUserId(sanitizeInput(user.sub));

    }else{

      setEmail(sanitizeInput(loggedInUser?.email));
      setUserId(sanitizeInput(loggedInUser?.userId));

    }


    console.log('PricingTablePage userId');
    console.log(userId);
    console.log('PricingTablePage email');
    console.log(email);


    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Mark data as loaded after setting state
    setDataLoaded(true);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
      <Heading as="h2" size="xl" textAlign="center" mb={4}>
        Choose a Plan
      </Heading>
      {isAuthenticated && dataLoaded && email && userId ? (
        <stripe-pricing-table
        pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
        publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={email} // Pass the authenticated user's email
          client-reference-id={userId} // Pass the authenticated user's ID
        ></stripe-pricing-table>
      ) : (
        <Text textAlign="center">Please log in to subscribe to a plan.</Text>
      )}
    </Box>
  );
};

export default PricingTablePage;
