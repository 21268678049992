import React, { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Box, Text, Image, useToast } from '@chakra-ui/react'
import SignInImage from '../../../assets/img/signin/realty-login.webp'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const LoginButton = () => {
	const toast = useToast()
	const { loginWithRedirect, isAuthenticated, loginWithPopup, user } = useAuth0()
	console.log('isAuthenticated', isAuthenticated)
	const history = useHistory()


	useEffect(() => {
		if (
			isAuthenticated &&
			user// &&
			// localStorage.getItem('isSignUp') !== 'true'
		) {
			axios
				.get(`${process.env.REACT_APP_BACKEND}/user/${user.email}`)
				.then((res) => {
					if (res.data.user) {
						localStorage.setItem(
							'logged_in_user',
							JSON.stringify(res.data.user)
						)
						history.push({ pathname: '/admin/dev' })
					}
					toast({
						title: 'Authentication successful',
						description: 'You have successfully logged in',
						status: 'success',
						position: 'top-right',
						duration: 3000,
					})
				})
				.catch((err) => {
					console.log(err, 'Error')
					toast({
						title: 'Authentication failed',
						description: err.response.data.message || err.message,
						status: 'error',
						position: 'top-right',
						duration: 3000,
					})
				})
		}
	}, [history, isAuthenticated, user])

	const handleSignUp = useCallback(async () => {
		// setIsSignUp(true)
		localStorage.setItem('isSignUp', 'true')
		await loginWithPopup({
			authorizationParams: {
				screen_hint: 'signup',
			},
		})
		history.push('/auth/create-account')
	}, [])

	return (
		<Box
			display='flex'
			minHeight='100vh'
			minWidth='100%'
			boxSizing='border-box'
		>
			<Box
				display={{ md: 'flex', base: 'none' }}
				alignItems={'center'}
				justifyContent={'center'}
				width={'100%'}
				flex={1}
				padding={'96px'}
				boxSizing='border-box'
			>
				<Image src={SignInImage} width={'80%'} alt='Scale and Grow Realty' />
			</Box>
			<Box
				display='flex'
				flexDirection={'column'}
				alignItems={'flex-start'}
				justifyContent={'center'}
				gap='24px'
				flex={1}
				backgroundColor='#e2ecff'
				padding={{ md: '48px', base: '24px' }}
				boxSizing='border-box'
			>
				<Box display='flex' flexDirection='column'>
					<Text fontSize='5xl' lineHeight={1.25}>
						Welcome to
					</Text>
					<Text fontSize='5xl' as='b' color='#407BFF' lineHeight={1.25}>
						Scale & Grow Realty
					</Text>
				</Box>
				<Text fontSize='xl'>Making Real Estate Dreams a Reality.</Text>
				<Button
					colorScheme={'messenger'}
					borderRadius={'12px'}
					minWidth={'60%'}
					onClick={() => loginWithRedirect()}
				>
					Login to proceed
				</Button>
				<Button
					border={'2px solid #407BFF'}
					borderRadius={'12px'}
					minWidth={'60%'}
					onClick={handleSignUp}
				>
					Sign Up
				</Button>
			</Box>
		</Box>
	)
}

export default LoginButton