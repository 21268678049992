//import WebSocket from "isomorphic-ws";
// const ws = new WebSocket(
//   `wss://${process.env.REACT_APP_API_URL.replace("https://", "")}/ws/frontend`
// );
const ws =null;
const logSpecs = {
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR",
  DEBUG: "DEBUG",
  FATAL: "FATAL",
};

const logColors = {
  INFO: "\u001b[0m",
  WARN: "\u001b[33m",
  ERROR: "\u001b[31m",
  DEBUG: "\u001b[0m",
  FATAL: "\u001b[31m",
};

const logFunctions = {
  INFO: console.info,
  WARN: console.warn,
  ERROR: console.error,
  DEBUG: console.debug,
  FATAL: console.error,
};

export class Logger {
  // Define the name of the logger

  constructor(name) {
    this.name = name;
  }

  generateContext(level) {
    return `\u001b[0m\u001b[35m${new Date().toLocaleTimeString()}  ${
      this.name
    } \u001b[1m${logColors[level]}${level}:${logColors[level]}`;
  }

  print(level, ...args) {
    const stringArgs = args.map((arg) => {
      if (typeof arg === "object") {
        return JSON.stringify(arg);
      }
      return String(arg);
    });
    logFunctions[level](this.generateContext(level), ...args);

    // if (ws.readyState === WebSocket.OPEN) {
    //   ws.send(
    //     JSON.stringify({
    //       message: stringArgs,
    //       level: level,
    //       name: this.name,
    //     })
    //   );
    // } else if (ws.readyState === WebSocket.CONNECTING) {
    //   console.warn(
    //     "WebSocket connection is still connecting. Unable to send data."
    //   );
    // } else {
    //   console.warn(
    //     "WebSocket connection is not open. Attempting to connect..."
    //   );
    //   ws.addEventListener("open", () => {
    //     ws.send(`${this.generateContext(level)} ${stringArgs}\u001b[0m`);
    //   });
    // }
  }

  log(...args) {
    this.print(logSpecs.INFO, ...args);
  }

  warn(...args) {
    this.print(logSpecs.WARN, ...args);
  }

  error(...args) {
    const error = new Error();
    const errorFunction = error.stack
      .split(/\n/)[2]
      .trim()
      .split(/\s+/)[1]
      .replace(/\(/, "");
    this.print(logSpecs.ERROR, `Error function triggered at ${this.name}/${errorFunction}.`);
    this.print(logSpecs.ERROR, ...args);
  }

  debug(...args) {
    this.print(logSpecs.DEBUG, ...args);
  }

  fatal(...args) {
    this.print(logSpecs.FATAL, ...args);
  }
}

export function log(originalFunction) {
  const error = new Error();
  const callerFunction = error.stack
    .split(/\n/)[2]
    .trim()
    .split(/\s+/)[1]
    .replace(/\(/, "");
  const callerFunctionCaller = error.stack
    .split(/\n/)[3]
    .trim()
    .split(/\s+/)[1]
    .replace(/\(/, "");
  const logger = new Logger(`${callerFunction}/${originalFunction.name}`);
  logger.log(`Function called by ${callerFunctionCaller}/${callerFunction}`);
  return originalFunction;
}
