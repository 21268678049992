import React, { useState, useEffect, useCallback } from "react";
import {
  ChakraProvider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  FormErrorMessage,
  Button,
  Flex,
  ModalFooter,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { MdAdd } from "react-icons/md";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const PropertyFieldsModal = ({
  isOpen,
  onClose,
  fields,
  proprtyErrors,
  handleFieldChange,
  handleDeleteField,
  setIsCustomFieldRequired,
  handleSubmitPropertyForm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Property fields</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {fields.map((field, index) => (
              <Box key={index} mb={2}>
                <FormControl isInvalid={proprtyErrors[field.label]}>
                  <FormLabel>{field.label}</FormLabel>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Input
                      type={field.type}
                      value={field.value}
                      onChange={(e) =>
                        handleFieldChange(index, field.label, e.target.value)
                      }
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      color="red"
                      ml={2}
                      aria-label="Delete Field"
                      onClick={() => handleDeleteField(field.id)}
                    />
                  </Flex>
                  <FormErrorMessage>
                    {proprtyErrors[field.label]}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            ))}

            <Button
              width="full"
              colorScheme="green"
              leftIcon={<Icon as={MdAdd} />}
              onClick={() => setIsCustomFieldRequired(true)}
              mt={4}
            >
              Add Field
            </Button>
          </Box>
          <ModalFooter pr={0}>
            <Flex gap={3} alignItems="center" justifyItems="flex-end">
              <Button colorScheme="red" onClick={onClose}>
                Close
              </Button>
              <Button colorScheme="blue" onClick={handleSubmitPropertyForm}>
                Submit
              </Button>
            </Flex>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ParentComponent = () => {
  const [isAddRequired, setIsAddRequired] = useState(true); // Set the modal to open initially
  const [formValues, setFormValues] = useState({
    name: '',
    address: '',
    contacts: '',
    'agent contacts': '',
    house: '',
    bathrooms: '',
    price: 0,
    rooms: 0,
  })
  const [fields, setFields] = useState([
    { id: "1", label: "Name", name: "Name", value: "", type: "text" },
    { id: "2", label: "Address", name: "Address", value: "", type: "text" },
    {
      id: "4",
      label: "Agent contacts",
      name: "AgentContacts",
      value: "",
      type: "text",
    },
    { id: "5", label: "Property Type", name: "Property Type", value: "", type: "text" },
    { id: "6", label: "Bathrooms", name: "Bathrooms", value: "", type: "text" },
    { id: "9", label: "Rooms", name: "Rooms", value: "", type: "text" },
    { id: "8", label: "Price", name: "Price", value: "", type: "text" },
  ]);
  const [customField, setCustomField] = useState({
    label: '',
    name: '',
    value: '',
    type: '',
  })
  const [agentInfo, setAgentInfo] = useState(null)
  const [isCustomFieldRequired, setIsCustomFieldRequired] = useState(false);
  const [proprtyErrors, setPropertyErrors] = useState({})
  const [errors, setErrors] = useState({})

  const { user } = useAuth0()
  const toast = useToast()
  const history = useHistory()

  useEffect(() => {
    const fetchAgents = async () => {
      console.log('User:', user)
      try {
        if (user && agentInfo === null) {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND + '/agents/byemail/' + user.email
          )

          console.log('response', response)

          if (response.data.agent !== null) {
            console.log(response)
            setAgentInfo(response.data.agent)
          }
        }
      } catch (error) {
        console.log('Error fetching agents:', error)
        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND +
            '/agents/agentName/' +
            'developer yuugo'
          )

          console.log('response', response)

          if (response.data.agent !== null) {
            console.log(response)
            setAgentInfo(response.data.agent)
          }
        } catch (error) {
          console.log('Error fetching agents:', error)
        }
      }
    }
    fetchAgents()
  }, [user])

  const validateCustomFields = () => {
    const newErrors = {}
    const { label, name, type } = customField

    if (!label) {
      newErrors.label = 'Label is required'
    }
    if (!name) {
      newErrors.name = 'Name is required'
    }
    if (!type) {
      newErrors.type = 'Type is required'
    }
    return newErrors
  }

  const handleFieldChange = (index, fieldName, fieldValue) => {
    const updatedFields = [...fields]
    let field = updatedFields[index]
    field = { ...field, value: fieldValue }
    updatedFields[index] = field
    setFields(updatedFields)
    setFormValues({ ...formValues, [fieldName.toLowerCase()]: fieldValue })
    if (Object.keys(proprtyErrors).length > 0) {
      delete proprtyErrors[fieldName]
      setPropertyErrors({ ...proprtyErrors })
    }
  };

  const handleDeleteField = (id) => {
    const newFields = fields.filter((field) => field.id !== id);
    setFields(newFields);
  };


  const handleCloseModal = useCallback(() => {
    setIsAddRequired(false)
    setPropertyErrors({})
    setFields([
      { id: '1', label: 'Name', name: 'Name', value: '', type: 'text' },
      { id: '2', label: 'Address', name: 'Address', value: '', type: 'text' },
      {
        id: '4',
        label: 'Agent contacts',
        name: 'AgentContacts',
        value: '',
        type: 'text',
      },
      { id: '5', label: 'Property Type', name: 'Property Type', value: '', type: 'text' },
      {
        id: '6',
        label: 'Bathrooms',
        name: 'Bathrooms',
        value: '',
        type: 'text',
      },
      { id: '9', label: 'Rooms', name: 'Notes', value: '', type: 'text' },
      { id: '8', label: 'Price', name: 'Price', value: '', type: 'text' },
    ])
    history.push('/admin/openhouse')
  }, [])

  const handleValidatePropertyForm = () => {
    let newErrors = {}
    fields.forEach((field) => {
      console.log('Field in loop:', field)
      if (!field.value) {
        let fieldKey = field.label
        newErrors = { ...newErrors, [fieldKey]: `${field.label} is required` }
      }
    })
    setPropertyErrors({ ...newErrors })
    return newErrors
  }

  const handleAddField = () => {
    const newFieldId = fields.length + 1
    if (Object.keys(validateCustomFields()).length > 0) {
      setErrors(validateCustomFields())
      return
    }
    setFields([...fields, { ...customField, id: newFieldId }])
    setIsCustomFieldRequired(false)
    setCustomField({ label: '', name: '', value: '', type: '' })
    setErrors({})
  };

  const handleChangeCustomField = (field, value) => {
    if (Object.keys(errors).length > 0) {
      delete errors[field]
      setErrors(errors)
    }
    setCustomField({ ...customField, [field]: value })
  }

  const handleSubmitPropertyForm = async () => {
    if (Object.keys(handleValidatePropertyForm()).length > 0) {
      return
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
        '/agents/addProperty/' +
        agentInfo.email,
        {
          property: formValues,
        }
      )
      toast({
        title: 'Property Listed',
        description: 'Property is successfully listed.',
        status: 'success',
        duration: 3000,
        position: 'top-right'
      })
      setAgentInfo(response.data.agent)
    } catch (error) {
      console.log('Error submitting property form:', error)
      toast({
        title: 'Server Error',
        description: 'Internal Server Error',
        status: 'error',
        duration: 3000,
        position: 'top-right'
      })
    }
    handleCloseModal()
  };

  const handleCloseCustomModal = () => {
    setIsCustomFieldRequired(false)
    setCustomField({ label: '', name: '', value: '', type: '' })
    setErrors({})
  }

  return (
    <ChakraProvider>
      <Box>
        <PropertyFieldsModal
          isOpen={isAddRequired}
          onClose={handleCloseModal}
          fields={fields}
          proprtyErrors={proprtyErrors}
          handleFieldChange={handleFieldChange}
          handleDeleteField={handleDeleteField}
          setIsCustomFieldRequired={setIsCustomFieldRequired}
          handleSubmitPropertyForm={handleSubmitPropertyForm}
        />
      </Box>
      <Modal
        isOpen={isCustomFieldRequired}
        onClose={handleCloseCustomModal}
        size={'md'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom field</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w={'100%'}>
              <FormControl isInvalid={errors.label} mb={2}>
                <FormLabel>Label</FormLabel>
                <Input
                  required
                  value={customField.label}
                  onChange={(e) =>
                    handleChangeCustomField('label', e.target.value)
                  }
                />
                <FormErrorMessage>{errors.label}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  required
                  value={customField.name}
                  onChange={(e) =>
                    handleChangeCustomField('name', e.target.value)
                  }
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl mb={2}>
                <FormLabel>Value</FormLabel>
                <Input
                  required
                  value={customField.value}
                  onChange={(e) =>
                    handleChangeCustomField('value', e.target.value)
                  }
                />
              </FormControl>
              <FormControl isInvalid={errors.type} mb={2}>
                <FormLabel>Type</FormLabel>
                <Input
                  required
                  value={customField.type}
                  onChange={(e) =>
                    handleChangeCustomField('type', e.target.value)
                  }
                />
                <FormErrorMessage>{errors.type}</FormErrorMessage>
              </FormControl>
            </Box>
            <ModalFooter pr={0}>
              <Flex gap={3} alignItems={'center'} justifyItems={'flex-end'}>
                <Button colorScheme='red' onClick={handleCloseCustomModal}>
                  Close
                </Button>
                <Button colorScheme='blue' onClick={handleAddField}>
                  Add
                </Button>
              </Flex>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ParentComponent;
