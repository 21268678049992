import { Box, Heading } from '@chakra-ui/layout'
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Flex,
  InputLeftElement,
  InputGroup,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { BiSort } from 'react-icons/bi'
import { FaSearch, FaFilter, FaSort } from 'react-icons/fa'
import { InviteModal } from './components/InviteModal'
import SortModal from './components/SortModal'
import FilterModal from './components/FilterModal'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

const mockTeamData = [
  // {
  //   userid: 1,
  //   status: 'active',
  //   role: 'Admin',
  //   email: 'james@yuugo.ai',
  //   subscriptiontier: 'premium',
  //   orgid: '12',
  //   orgname: 'Yuugo',
  //   subscription: {
  //     subscriptionStart: '2024-04-01',
  //     subscriptionEnd: '2024-06-01',
  //     activeStatus: 'active',
  //   },
  //   username: 'James A',
  // },
  // {
  //   userid: 2,
  //   status: 'active',
  //   role: 'Member',
  //   email: 'carl@yuugo.ai',
  //   subscriptiontier: 'premium',
  //   orgid: '12',
  //   orgname: 'Yuugo',
  //   subscription: {
  //     subscriptionStart: '2024-04-01',
  //     subscriptionEnd: '2024-06-01',
  //     activeStatus: 'active',
  //   },
  //   username: 'Carl B',
  // },
  // {
  //   userid: 3,
  //   status: 'active',
  //   role: 'Member',
  //   email: 'james@yuugo.ai',
  //   subscriptiontier: 'premium',
  //   orgid: '12',
  //   orgname: 'Yuugo',
  //   subscription: {
  //     subscriptionStart: '2024-04-01',
  //     subscriptionEnd: '2024-06-01',
  //     activeStatus: 'active',
  //   },
  //   username: 'James A',
  // },
]

export const Team = (props) => {
  const [teamData, setTeamData] = useState(mockTeamData)
  const [searchTerm, setSearchTerm] = useState('')
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [isSortModalOpen, setIsSortModalOpen] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false);


  const { user } = useAuth0()

  const toast = useToast()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/team/listMembers/12`).then((res) => {
      console.log(res.data.members, 'Members')
    }).catch(err => {
      if (err.response.status === 404) {
        // toast({
        //   title: 'Not found',
        //   description: 'No team members found for this organisation',
        //   status: 'error',
        //   duration: 3000,
        //   position: 'top-right'
        // })
      }
    })
  }, [])

  const sendInvite = useCallback((email) => {
    axios.post(`${process.env.REACT_APP_BACKEND}/team/sendInvite`, {
      "email": email,
      "userEmail": user.email,
      "username": user.name,
      content: `<span>Hi</span><br/><div>
      This mail is from <strong>${user.name}</strong> regarding the invitation for S&G Realty.
      Click below link to proceed.
      <a href="${window.location.origin}/#/auth/create-account?orgid=123&orgname=Yuugo">Click Here to proceed</a>
      </div>`
    }).then((res) => {
      toast({
        title: 'Sent',
        description: 'Email Sent',
        status: 'success',
        duration: 3000,
        position: 'top-right'
      })
    }).catch(err => {
      toast({
        title: 'Error',
        description: 'Internal Sever Error',
        status: 'error',
        duration: 3000,
        position: 'top-right'
      })
    })
  }, [])

  const handleSearch = useCallback((searchTerm) => {
    const filteredTeamData = mockTeamData.filter(
      (team) => {
        return team.username.includes(searchTerm) ||
          team.email.includes(searchTerm) ||
          team.orgname.includes(searchTerm)
      }
    )
    setTeamData(filteredTeamData)
  }, [teamData])

  const handleSort = useCallback((sortOption) => {
    const sortOpt = sortOption.split('-')
    setTeamData(prev => prev.sort((a, b) => {
      if (sortOpt[1] === 'asc') {
        return String(a[sortOpt[0]]).localeCompare(b[sortOpt[0]])
      } else {
        return String(b[sortOpt[0]]).localeCompare(a[sortOpt[0]])
      }
    }))
  }, [])

  const handleFilter = useCallback((filter) => {
    console.log(filter, 'Filters');
    if (filter.term && filter.option) {
      setTeamData(prev => prev.filter(team => String(team[filter.option])?.toLowerCase().includes(String(filter.term).toLowerCase())))
    } else {
      setTeamData(mockTeamData)
    }
  }, [])
  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.innerWidth <= 768;
      setIsMobile(isMobileDevice);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      textAlign={'center'}
      padding={isMobile ? '5' : '48px'}
      display={'flex'}
      flexDirection={'column'}
      gap={'24px'}
      height={isMobile ? "100%" : '100%'}
      w={isMobile ? "100%" : '100%'}
    >
      <Heading>
        Team{' '}
        <Button borderRadius={'8px'} colorScheme={'telegram'} float={'right'} onClick={() => {
          setIsInviteModalOpen(true)
        }}>
          Invite
        </Button>
      </Heading>

      <Flex mb={4}>
        <InputGroup>
          <InputLeftElement pointerEvents='none'>
            <FaSearch />
          </InputLeftElement>
          <Input
            placeholder='Search by name'
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value)
            }}
            mr={2}
          />
        </InputGroup>
        <Flex gap={3}>
          <Button
            colorScheme={'telegram'}
            onClick={() => setIsFilterModalOpen(true)}
          >
            <FaFilter />
          </Button>
          <Button
            colorScheme={'telegram'}
            onClick={() => setIsSortModalOpen(true)}
          >
            <BiSort />
          </Button>
        </Flex>
      </Flex>
      <TableContainer
        backgroundColor={'white'}
        borderRadius={'24px'}
        padding={isMobile ? "0" : '12px'}
      >
        <Table variant={'striped'} colorScheme={'telegram'}>
          <Thead borderBottom={'2px solid black'}>
            <Th>Full Name</Th>
            <Th>Account Type</Th>
            <Th>Email Address</Th>
            <Th>Date joined</Th>
          </Thead>
          <Tbody>
            {teamData.map((team) => (
              <Tr key={team.userid}>
                <Td>{team.username}</Td>
                <Td>{team.role}</Td>
                <Td>{team.email}</Td>
                <Td>{team.subscription.subscriptionStart}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <InviteModal open={isInviteModalOpen} onClose={() => { setIsInviteModalOpen(false) }} onInvite={sendInvite} />
      <SortModal isOpen={isSortModalOpen} onClose={() => { setIsSortModalOpen(false) }} onSort={handleSort} />
      <FilterModal isOpen={isFilterModalOpen} onClose={() => { setIsFilterModalOpen(false) }} onFilter={handleFilter} />
    </Box>
  )
}
