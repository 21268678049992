import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    FormControl,
    FormLabel,
    Input,
    Select,
    Text,
    Editable,
    EditablePreview,
    EditableInput,
    ButtonGroup,
    IconButton,
    Flex,
    List,
    ListItem,
    Link,
    useEditableControls,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    CheckboxGroup,
    Checkbox,
    HStack,
    Icon,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    useDisclosure

} from '@chakra-ui/react';
import toast from 'react-hot-toast'
import axios from 'axios'
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { FaLink } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { GoMoveToEnd } from "react-icons/go";
import { useAuth0 } from '@auth0/auth0-react'


const customer = {
    name: "John Doe",
    properties: [
        "1234 Maple Street - 4 Bedroom, 2 Bath",
        "5678 Oak Avenue - Studio Apartment",
        "9101 Pine Lane - 2 Bedroom Condo"
    ]
};
const informationexample = {
	text: 'Business hours: Mon - Fri, 9am - 6pm\nRequirements for applying for a mortgage loan:\nMinimum income of $3,000 USD\nCredit history of at least 2 years\nMinimum savings of $10,000 USD\nPhone: +1 (555) 123-4567\nEmail: "info@company.com"',
}

function ClientProfile({selectedClient}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [availability, setAvailability] = useState({ 
        Monday: [9, 17], 
        Tuesday: [9, 17], 
        Wednesday: [9, 17], 
        Thursday: [9, 17], 
        Friday: [9, 17], 
        Saturday: [9, 17], 
        Sunday: [9, 17] 
    });
    const [availableDays, setAvailableDays] = useState([]);
    const [toneResponse, setToneResponse] = useState('');
	const [agent, setAgent] = useState(null)
	const [information, setInformation] = useState(informationexample)
    const [companyDetails, setCompanyDetails] = useState({
		name: 'Keller Realtors',
		address: '123 Main St, Anytown USA',
		phone: '+1 (555) 987-6543',
		email: 'info@company.com',
	})

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user } = useAuth0();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const formatHour = (hour) => `${hour}:00`;

    const handleDaysChange = (selectedDays) => {
        setAvailableDays(selectedDays);
    };
	const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const saveParameters = async () => {
		console.log("saveParameters")
		try {
			const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`)
			setAgent(agent.data.agent)
			const dataparameters = {
				tone: toneResponse,
				rangehours: JSON.stringify(availability),
				agentid: agent.data.agent.id.toString(),
				clientid: selectedClient.id.toString(),
				companyinformation: information.text,
				agentinformation: JSON.stringify(agent.data.agent),
				companydetails: JSON.stringify(companyDetails),
				rangedays: JSON.stringify(availableDays),
			}
			try {
				const exist = await axios.get(`${process.env.REACT_APP_API_URL}/ai/existParameter/${agent.data.agent.id}/${selectedClient.id}`)
				console.log(exist.data)
				if (exist.data.error === "Parameter not found") {
					console.log("create")
					const newP = await axios.post(`${process.env.REACT_APP_API_URL}/ai/createParameter`, dataparameters)
					// setAgentDetails(JSON.parse(newP.data.agentinformation))
					setCompanyDetails(JSON.parse(newP.data.companydetails))
					setToneResponse(newP.data.tone)
					// setInformation({ text: newP.data.companyinformation })

					// console.log(newP.data)
					toast.success('Parameters saved successfully')
				}
				else {
					const updateParameter = await axios.put(`${process.env.REACT_APP_API_URL}/ai/updateParameter/${agent.data.agent.id}`, dataparameters)
					console.log(updateParameter.data)
					// setAgentDetails(JSON.parse(updateParameter.data.agentinformation))
					setCompanyDetails(JSON.parse(updateParameter.data.companydetails))
					setToneResponse(updateParameter.data.tone)
					toast.success('Parameters saved successfully')
				}

			} catch (error) {
				console.log("por aca")
				const updateParameter = await axios.put(`${process.env.REACT_APP_API_URL}/ai/updateParameter/${agent.data.agent.id}`, dataparameters)

				// setAgentDetails(JSON.parse(updateParameter.data.agentinformation))
				setCompanyDetails(JSON.parse(updateParameter.data.companydetails))
				setToneResponse(updateParameter.data.tone)
				toast.success('Parameters saved successfully')
			}

		} catch (error) {
			console.log(error)
			toast.error('An error occurred while saving the parameters')
		}
	}

    useEffect(() => {
		const fecthAgent = async () => {
			try {
				const agent = await axios.get(`${process.env.REACT_APP_API_URL}/agents/byemail/${user.email}`)
				const parameters = await axios.get(`${process.env.REACT_APP_API_URL}/ai//existParameter/${agent.data.agent.id}/${selectedClient.id}`)
				if (parameters) {
					setToneResponse(parameters.data.tone)
					// setAgentDetails(JSON.parse(parameters.data.agentinformation))
					setCompanyDetails(JSON.parse(parameters.data.companydetails))
					setAvailability(JSON.parse(parameters.data.rangehours))
					setAvailableDays(JSON.parse(parameters.data.rangedays))
					// setInformation({ text: parameters.data.companyinformation })
				}
                else{
                    setToneResponse('')
                    setAvailability({
                        Monday: [9, 17],
                        Tuesday: [9, 17],
                        Wednesday: [9, 17],
                        Thursday: [9, 17],
                        Friday: [9, 17],
                        Saturday: [9, 17],
                        Sunday: [9, 17]
                    })
                    setAvailableDays([])
                    setInformation(informationexample)
                }
			} catch (error) {
				console.error('An error occurred while fetching the agent parameters')
			}
		}
		fecthAgent()
	}, [])

    return (
        <Box p={5} shadow='md' borderWidth='1px'>
            <Flex>
                <Text fontSize='xl' fontWeight='bold'>Client Details</Text>

            </Flex>
            <Text mt={4}>Name: {selectedClient.name}</Text>

            {daysOfWeek.map((day) => (
                <Box key={day} mb={4}>
                    <Text mt={4}>
                        {day} Availability:
                        <br />
                        {formatHour(availability[day][0])} to {formatHour(availability[day][1])}
                    </Text>
                    <RangeSlider
                        defaultValue={availability[day]}
                        min={0}
                        max={23}
                        step={1}
                        onChange={(value) =>
                            setAvailability((prev) => ({ ...prev, [day]: value }))
                        }
                    >
                        <RangeSliderTrack>
                            <RangeSliderFilledTrack />
                        </RangeSliderTrack>
                        <RangeSliderThumb index={0} />
                        <RangeSliderThumb index={1} />
                    </RangeSlider>
                </Box>
            ))}

            <Box p={5} shadow='md' borderWidth='1px' borderRadius='lg' maxW='400px' m='20px auto' bg='white' color='gray.700'>
                <VStack spacing={5} align="stretch">
                    <FormControl>
                        <FormLabel>Select Available Days</FormLabel>
                        <CheckboxGroup value={availableDays} onChange={handleDaysChange}>
                            <VStack align="start">
                                {daysOfWeek.map((day) => (
                                    <Checkbox key={day} value={day} isChecked={availableDays.includes(day)}>
                                        {day}
                                    </Checkbox>
                                ))}
                            </VStack>
                        </CheckboxGroup>
                    </FormControl>

                    <Box>
                        <Text fontSize='lg' fontWeight='bold'>Selected Days:</Text>
                        {availableDays.length > 0 ? (
                            <Text>{availableDays.join(', ')}</Text>
                        ) : (
                            <Text color='gray.500'>No days selected</Text>
                        )}
                    </Box>
                </VStack>
            </Box>
            <Text mt={4}>Tone:</Text>

            <Select placeholder='Select response tone' value={toneResponse} onChange={(e) => setToneResponse(e.target.value)}>
                <option value='pleasant'>Pleasant</option>
                <option value='business'>Business</option>
                <option value='friendly'>Friendly</option>
                <option value='excited'>Excited</option>
            </Select>
            <Flex gap={5}>
            <Button mt={4} onClick={onOpen} variant={"outline"} colorScheme={"purple"}>Interested Properties</Button>
            <Button mt={4} variant={"outline"} colorScheme={"messenger"} onClick={saveParameters}>Save</Button>
            </Flex>

            {/* Modal for displaying properties */}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{customer.name}'s Interested Properties</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            {customer.properties.map((property, index) => (
                                <Box
                                    key={index}
                                    p={4}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    overflow="hidden"
                                    _hover={{ boxShadow: 'lg' }}
                                >
                                    <HStack spacing={3}>
                                        <Icon as={FaHome} w={6} h={6} color="teal.500" />
                                        <Text fontWeight="medium">{property}</Text>
                                    </HStack>
                                </Box>
                            ))}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default ClientProfile;
