import { Box, Link, Text, Heading } from '@chakra-ui/layout'
import {
	FormControl,
	Input,
	FormLabel,
	Button,
	useToast,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { Link as ReactRouterLink, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

export const CreateAccount = (props) => {
	const [formData, setFormData] = useState({})

	const toast = useToast()
	const { user } = useAuth0()

	const history = useHistory()

	const handleChange = useCallback((field, value) => {
		setFormData((prev) => ({ ...prev, [field]: value }))
	}, [])

	const handleSubmit = useCallback(() => {
		if (Object.values(formData).every((v) => v)) {
			axios
				.post(`${process.env.REACT_APP_BACKEND}/user/signup`, {
					userId: user.sub,
					username: user.nickname,
					name: formData.name,
					email: user.email,
					role: formData.role,
					company_name: formData.company_name,
				})
				.then((res) => {
					toast({
						title: 'Signed up successfully',
						description: 'You are successfully registered.',
						status: 'success',
						position: 'top-right',
						duration: 3000,
					})

					localStorage.removeItem('isSignUp')
				//	history.push({ pathname: '/pricing' })
					history.push('/pricing');

					//history.push({ pathname: '/auth/account-plans' })

				})
				.catch((err) => {
					console.error(err)
					toast({
						title: 'Sign up failed',
						description: err?.response?.data?.message || err.message,
						status: 'error',
						position: 'top-right',
						duration: 3000,
					})
				})
		} else {
			toast({
				title: 'Enter all details',
				status: 'error',
				duration: 3000,
				position: 'top-right',
			})
		}
	}, [formData, user])

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			gap={'32px'}
			alignItems={'center'}
			justifyContent={'center'}
			height={'100vh'}
			width={'100vw'}
		>
			<Heading color={'blue.400'}>
				<Text color={'black'} as={'span'}>
					Scale
				</Text>{' '}
				&{' '}
				<Text color={'black'} as={'span'}>
					Grow
				</Text>{' '}
				Realty
			</Heading>
			<Box textAlign={'center'}>
				<Text fontSize={'3xl'} as={'b'}>
					Create your account
				</Text>
				<Text fontSize={'l'} color={'GrayText'} as={'p'}>
					Enter your details to get started
				</Text>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'column'}
				gap={'12px'}
				alignItems={'center'}
				minWidth={'400px'}
			>
				<FormControl isRequired>
					<FormLabel>Full Name</FormLabel>
					<Input
						type='text'
						value={formData.fullName}
						onChange={(e) => handleChange('name', e.target.value)}
					/>
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Company Name</FormLabel>
					<Input
						type='text'
						value={formData.company_name}
						onChange={(e) => handleChange('company_name', e.target.value)}
					/>
				</FormControl>
				<FormControl isRequired>
					<FormLabel>Role within company</FormLabel>
					<Input
						type='text'
						value={formData.role}
						onChange={(e) => handleChange('role', e.target.value)}
					/>
				</FormControl>
				<Button
					colorScheme={'messenger'}
					minWidth={'400px'}
					borderRadius={'8px'}
					onClick={handleSubmit}
				>
					Create your account
				</Button>
			</Box>
			<Text fontSize={'large'} as={'span'}>
				Have an account?{' '}
				<Link as={ReactRouterLink} to={'/auth/signin'} color={'blue.400'}>
					Login
				</Link>
			</Text>
		</Box>
	)
}
