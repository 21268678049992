import { Box, Text } from '@chakra-ui/layout'
import { Button, Image } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import LogoutIllustration from '../../../assets/img/signin/logout.webp'

export default function Logout(props) {
  const history = useHistory()

  const handleLogout = useCallback(() => {
    history.push('/auth/signin')
  }, [])

  return (
    <Box
      w={'full'}
      h={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'16px'}
    >
      <Text fontWeight='bold' fontSize='48px' mb='20px'>
        Scale{' '}
        <Text
          fontWeight='bold'
          fontSize='48px'
          color={'blue.400'}
          as={'span'}
        >
          &
        </Text>{' '}
        Grow Realty
      </Text>
      <Image src={LogoutIllustration} width={'30%'} />
      <Text fontSize={'3xl'}>
        You have been logged out from the application
      </Text>
      <Button colorScheme={'telegram'} onClick={handleLogout}>
        Sign in again to continue
      </Button>
    </Box>
  )
}
