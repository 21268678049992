/* #version=0.0.0-0#8 default 2023-11-27T23:22:50 6B7AA2D78FE1F072 */
/* #version=0.0.0-0#7 default 2023-11-27T11:35:40 6925029795D6D34F */
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdAppShortcut,
  MdAccountCircle,
} from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { BsHouseFill } from "react-icons/bs";
import { MdPlaylistAddCheck } from "react-icons/md";

import { RiTeamFill } from "react-icons/ri";
import AdminCenter from "views/admin/admincenter/admincenterpg"
import { MdOutlineAddCircleOutline } from "react-icons/md";

import { BiBuildingHouse, BiLogOutCircle } from "react-icons/bi";
import mylisting from "views/admin/listing/listingpg";
import Client from "views/admin/client/clientpg";
import authSignin from "views/auth/authsignin";

import { VscSignIn } from "react-icons/vsc";



import Screening from "views/admin/screening";
import Schedule from "views/admin/schedule/Index";
import PropertyFieldsModal from "views/admin/listing/component/listingform";




import { BsPersonPlusFill } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { IoOptionsSharp } from "react-icons/io5";
import { PiSignIn } from "react-icons/pi";
import { CreateAccount } from "views/auth/createAccount/CreateAccount";
import { AccountPlans } from "views/auth/createAccount/AccountPlans";
import Apps from "views/admin/aiassist/aiassistpg";
import Plaid from "views/admin/plaid";
import { MdFileOpen } from "react-icons/md";

import { Account } from "views/admin/account/Account";
import { Team } from "views/admin/team/team";
import Logout from "views/auth/logout/Logout";

const routes = [
  {
    name: "Admin Center",
    layout: "/admin",
    icon: <Icon as={GrUserAdmin} width="20px" height="20px" color="inherit" />,
    path: "/admin",
    component: AdminCenter,
  },
  {
    name: 'Logout',
    layout: '/auth',
    path: '/logout',
    icon: <Icon as={BiLogOutCircle} width="20px" height="20px" color="inherit" />,
    component: Logout
  },
  {
    name: 'Create Account',
    layout: '/auth',
    path: '/create-account',
    icon: <Icon as={BsPersonPlusFill} width="20px" height="20px" color="inherit" />,
    component: CreateAccount
  },  {
    name: "Sign In",
    layout: "/auth",
    icon: <Icon as={PiSignIn} width="20px" height="20px" color="inherit" />,
    path: "/signin",
    component: authSignin,
  },
  {
    name: 'Account Plans',
    layout: '/auth',
    path: '/account-plans',
    icon: <Icon as={BsPersonPlusFill} width="20px" height="20px" color="inherit" />,
    component: AccountPlans
  },

  {
    name: "Dashboard",
    layout: "/admin",
    path: "/scheduling",
    icon: <Icon as={FaRegCalendarCheck} width="20px" height="20px" color="inherit" />,
    component: Schedule,
  },

  {
    name: "AI Assist",
    layout: "/admin",
    icon: <Icon as={MdAppShortcut} width="20px" height="20px" color="inherit" />,
    path: "/apps",
    component: Apps,
  },
  {
    name: "Team",
    layout: "/admin",
    icon: <Icon as={RiTeamFill} width="20px" height="20px" color="inherit" />,
    path: "/team",
    component: Team,
  },
  {
    name: "Account",
    layout: "/admin",
    path: "/account",
    icon: <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />,
    component: Account,
  },

  {
    name: "My Listings",
    layout: "/admin",
    icon: <Icon as={BsHouseFill} width="20px" height="20px" color="inherit" />,
    path: "/mylisting",
    component: mylisting,
  }, {
    name: "Screening",
    layout: "/admin",
    icon: <Icon as={MdPlaylistAddCheck} width="20px" height="20px" color="inherit" />,
    path: "/screening",
    component: Screening,
  }, 
  {
    name: "User Plaid Screen",
    layout: "/user",
    icon: <Icon as={MdFileOpen} width="20px" height="20px" color="inherit" />,
    path: "/screen",
    component: Plaid,
  }, 
  {
    name: "Clients", // This is the main menu item
    layout: "/admin",
    icon: <Icon as={VscSignIn} width="20px" height="20px" />,
    path: "/dev", // This could be a dashboard or main page for the Agent section
    component: Client, // Adjust if you have a specific component for the main Agent Terminal
  },{
    name: "Add Property",
    layout: "/admin",
    icon: <Icon as={MdOutlineAddCircleOutline} width="20px" height="20px" color="inherit" />,

    path: "/addproperty",
    component: PropertyFieldsModal,
  },

];
export default routes;