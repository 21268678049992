import {
	Box,
	Flex,
	Button,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalFooter,
	FormControl,
	FormLabel,
	TabList,
	Tabs,
	Tab,
	TabPanel,
	TabPanels,
	Textarea,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	SimpleGrid,
	useColorModeValue,
	Icon,
	Card,
	InputGroup,
	InputLeftElement,
	Spinner,
	useToast,
	Select,
	Link,
	List,
	ListItem,
	Heading,
	Text,
} from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component'
import {
	MdArrowUpward,
	MdDelete,
	MdEdit,
	MdCheck,
	MdClose,
	MdBarChart,
	MdAttachMoney,
} from 'react-icons/md'
import Plaidlink from './plaidlink'
import { BiAddToQueue, BiDotsVertical } from 'react-icons/bi'

import { BsFillPlayFill } from 'react-icons/bs'
import { AddIcon, ViewIcon } from '@chakra-ui/icons'
import { FaSearch } from 'react-icons/fa'
import MiniStatistics from '../../../../components/card/MiniStatistics'
import IconBox from 'components/icons/IconBox'
import ClientProfile from './ClientProfile'
import { range, set } from 'lodash'

let documents = [
	{
		id: 1,
		name: 'Document 1.pdf',
		size: '1.5MB',
		uploadedBy: 'User A',
		uploadedAt: '2024-04-23',
		extension: 'pdf',
	},
	{
		id: 2,
		name: 'Document 2.docx',
		size: '2.0MB',
		uploadedBy: 'User B',
		uploadedAt: '2024-04-22',
		extension: 'docx',
	},
	{
		id: 3,
		name: 'Document 3.txt',
		size: '0.7MB',
		uploadedBy: 'User C',
		uploadedAt: '2024-04-21',
		extension: 'txt',
	},
]

function ViewTable({ initialApps }) {
	const [apps, setApps] = useState([
		{
			id: 1,
			client_name: 'John Joe',
			client_email: 'data@hotmail',
			client_number: '222-222-222',
			version: 'great',
			status: 'Active',
		},
		{
			id: 2,
			client_name: 'App B',
			client_email: 'data@hotmail',
			client_number: '333-333-333',
			version: 'great',
			status: 'Inactive',
		},
		// Add more hardcoded app objects as needed
	])
	const [editableRowId, setEditableRowmIds] = useState(null)
	const [editableRowname, setEditableRowmname] = useState(null)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalMoreOpen, setIsModalMoreOpen] = useState(false)

	const [types, setTypes] = useState('')
	const [loadBalancePercentages, setLoadBalancePercentages] = useState('')
	const [selectedRoute, setSelectedRoute] = useState('')
	const [routeInput, setRouteInput] = useState('')
	const [routes, setRoutes] = useState([])

	const [appName, setAppName] = useState('')
	const [appUrl, setAppUrl] = useState('')
	const [appNumber, setAppNumber] = useState('')
	const [version, setVersion] = useState('')
	const [status, setStatus] = useState('')
	const [editon, setEdition] = useState(false)
	const [curruser, setcurrentuser] = useState(null)
	const [documentsFile, setDocumentsFile] = useState(documents)

	const [currentclientname, setcurrentclientname] = useState(null)

	const fileInputRef = useRef(null)
	const brandColor = useColorModeValue('brand.500', 'white')
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100')
	//const [routes, setRoutes] = useState([]);
	const [clientsList, setClientsList] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [tabId, setTabId] = useState(0)
	const [isMobile, setIsMobile] = useState(false)
	const [search, setSearch] = useState('')
	const [note, setNote] = useState('')
	const [moreinfo, setMoreinfo] = useState({
		prices: 0,
		budget: 0,
		proximity: '',
		locations: '',
	})
	const [parameters, setParameters] = useState({
		tone: '',
		rangehours: '',
		agentid: '',
		clientid: '',
		agentinformation: {},
		rangedays: []
	})	

	const toast = useToast()

	const getClientsList = async (userId) => {
		try {
			setIsLoading(true)
			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND}/clients/clientlist`,
				{ userId }
			)
			
			console.log('response', response.data.clients)
			setClientsList(response.data.clients)
			setIsLoading(false)
			// toast({
			// 	title: 'Successfully fetched',
			// 	variant: 'solid',
			// 	isClosable: true,
			// 	position: 'top-right',
			// 	status: 'success',
			// })
		} catch (error) {
			console.log('Error in getClientsList:', error)
			setIsLoading(false)
			toast({
				title: error.message ? error.message : 'Something went wrong',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'error',
			})
		}
	}

	useEffect(() => {
		console.log('initialApps', initialApps)

		//setApps(initialApps);
		getClientsList(JSON.parse(localStorage.getItem('logged_in_user'))?.email)
	}, [])

	useEffect(() => {
		const handleResize = () => {
			const isMobileDevice = window.innerWidth <= 768
			setIsMobile(isMobileDevice)
		}

		const isMobileDevice = window.innerWidth <= 768
		setIsMobile(isMobileDevice)

		window.addEventListener('resize', handleResize)

		handleResize()

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	const runsms = (info) => {
		sendSMS()
		closeModal()
	}

	const openModal = (row) => {
		console.log('row', row)
		//client_name
		setcurrentuser(row)
		setcurrentclientname(row.name)

		setIsModalOpen(true)
	}

	const openMore = (row) => {
		console.log('row', row)
		if (row.notes !== null) {
			setNote(row.notes)
		}
		else {
			setNote('')
		}

		if (row.moreinfo !== null) {
			setMoreinfo(row.moreinfo)
		}
		else {
			setMoreinfo({})
		}

		//client_name
		//setEditableRowmIds(row.id);
		setEditableRowmname(row.name)
		setcurrentuser(row)

		setIsModalMoreOpen(true)
	}

	const setEditableRowId2 = (row) => {
		setEditableRowmIds(row.id)
		setEditableRowmname(row.client_name)

		setEdition(false)
		// Remove the line below that opens the modal
		// setIsModalOpen(true);
	}

	const setEditableRowId = (rowId) => {
		setEditableRowmIds(rowId)
		// Remove the line below that opens the modal
		// setIsModalOpen(true);
	}
	const closeModal = () => {
		// Call the sendSMS function when needed
		//sendSMS();
		setIsModalOpen(false)
		resetForm()
	}

	const closeMoreModal = () => {
		setIsModalMoreOpen(false)
		resetForm()
	}

	// Function to send SMS
	const sendSMS = async () => {
		try {
		  console.log('curruser', curruser);
	  
		  let accCode = JSON.parse(localStorage.getItem('logged_in_user'))?.accCode;
	  
		  const url = `${window.origin}/#/user/screen?id=${encodeURIComponent(curruser.id)}&agency=${encodeURIComponent(curruser.name)}&clientcode=${encodeURIComponent(curruser.clientcode)}&accCode=${encodeURIComponent(accCode)}`;
	  
		  const response = await axios.post(
			`${process.env.REACT_APP_BACKEND}/sms/sms`,
			{
			  smsMessage: `Here is the scale and grow screening link: ${url}`,
			  from: '+17208973072',
			  to: `+1${curruser.number}`,
			}
		  );
	  
		  console.log('SMS sent successfully', response);
		} catch (error) {
		  console.error('Error sending SMS', error);
		}
	  };
	  

	const resetForm = () => {
		setAppName('')
		setAppUrl('')
		setAppNumber('')
		setVersion('')
		setStatus('')
		setTypes('')
		setLoadBalancePercentages('')
		setSelectedRoute('')
		setRouteInput('')
		setRoutes([])
	}

	const addRoute = () => {
		if (routeInput.trim() !== '') {
			setRoutes([...routes, routeInput])
			setRouteInput('')
		}
	}

	const handleSave = () => {
		// Perform save logic here, using editableRowId, appName, appUrl, appNumber, version, status, types, loadBalancePercentages, and routes
		console.log('Saving row with ID:', editableRowId)
		console.log('App Name:', appName)
		console.log('App URL:', appUrl)
		console.log('App Number:', appNumber)
		console.log('Version:', version)
		console.log('Status:', status)
		console.log('Types:', types)
		console.log('Load Balance Percentages:', loadBalancePercentages)
		console.log('Routes:', routes)
		closeModal()
	}

	const handleclose = () => {
		// Perform save logic here, using editableRowId, appName, appUrl, appNumber, version, status, types, loadBalancePercentages, and routes

		closeMoreModal()
	}

	const fetchApps = async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_BACKEND}/deploy/apps/`
			)
			setApps(initialApps) // Assuming the API returns the array of apps directly
			console.log('response', response.data)
		} catch (error) {
			console.error('There was an error fetching the data:', error)
			// Handle the error appropriately in your application
		}
	}

	useEffect(() => {
		console.log('initialApps', initialApps)
		if (!process.env.REACT_APP_DEPLOY_MOCK_DATA) {
			fetchApps()
		} else {
			setApps(initialApps)
		}
		//setApps(initialApps);
	}, [initialApps])

	const softDeleteTask = async (id) => {
		if (!process.env.REACT_APP_DEPLOY_MOCK_DATA) {
			try {
				await axios.delete(`${process.env.REACT_APP_BACKEND}/clients/delete/${id}`)
				toast({
					title: 'Client successfully deleted',
					variant: 'solid',
					isClosable: true,
					position: 'top-right',
					status: 'success',
				})
				//await axios.put(`http://localhost:5001/deploy/apps/${id}/soft-delete`);
				console.log('App successfully marked as deleted')

				// Update tasks state to reflect the change
				let clientsListCopy = [...clientsList]
				const updatedTasks = clientsListCopy.map((client) => {
					if (client.id === id) {
						return { ...client, isDeleted: true } // Assuming you want to keep it in the state to maybe undo or just mark it
					}
					return client
				})
				setClientsList([...updatedTasks])
			} catch (error) {
				console.error('Error marking app as deleted', error)
			}
		} else {
			let clientsListCopy = [...clientsList]
			const updatedTasks = clientsListCopy.map((client) => {
				if (client.id === id) {
					return { ...client, isDeleted: true } // Assuming you want to keep it in the state to maybe undo or just mark it
				}
				return client
			})
			setClientsList([...updatedTasks])
		}
	}
	//clients/update
	const saveChanges = async (row) => {
		//if (!process.env.REACT_APP_DEPLOY_MOCK_DATA === "true") {
			try {
				await axios.put(`${process.env.REACT_APP_BACKEND}/clients/update/${row.id}`, row, {
				    headers: {
				        'Content-Type': 'application/json'
				    }
				});
				console.log('App successfully updated')
				setEditableRowId('') // Exit edit mode for the row
			} catch (error) {
				console.error('Error updating app', error)
			}
		// } else {
		// 	console.log('App successfully updated')
		// 	setEditableRowId('')
		// }

	}

	const handleEditChange = (id, name, value) => {
		setClientsList(
			clientsList.map((client) => {
				if (client.id === id) {
					return { ...client, [name]: value }
				}
				return client
			})
		)
	}
	//plaidlink
	const sampleData = [
		{
			id: 1,
			name: 'App A',
			app_url: `${process.env.REACT_APP_API_URL}/app-a`,
			date: '01/20/2024',
			version: '1.0.1',
			primaryNumber: '222-222-222',
		},
		{
			id: 2,
			name: 'App B',
			app_url: `${process.env.REACT_APP_API_URL}/app-b`,
			date: '01/20/2024',
			version: '1.0.0',
			primaryNumber: '222-222-222',
		},
		// Add more scenarios as needed
	]
	const columns = [
		{
			id: 1,
			name: 'Person Name',
			selector: (row) => row.name,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='name'
						value={row.name}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='Client Name'
						size='sm'
					/>
				) : (
					row.name
				),
		},
		{
			id: 2,
			name: 'cell Number',
			selector: (row) => row.number,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='number'
						value={row.number}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='App Name'
						size='sm'
					/>
				) : (
					row.number
				),
		},
		{
			id: 3,
			name: 'Property',
			selector: (row) => row.propertyname,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='property'
						value={row.propertyname}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='property'
						size='sm'
					/>
				) : (
					row.propertyname
				),
		},
		{
			id: 4,
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='email'
						value={row.email}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='App Name'
						size='sm'
					/>
				) : (
					row.email
				),
		},
		{
			id: 5,
			name: 'Agentless',
			selector: (row) => row.email,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='agentless'
						value={row.agentless}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='true or false'
						size='sm'
					/>
				) : (
					row.agentless
				),
		},
		{
			id: 6,
			name: 'Address',
			selector: (row) => row.address,
			sortable: true,
			reorder: true,
			style: {
				'& > *:first-child': {
					overflow: 'unset !important',
				},
			},
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Input
						name='address'
						value={row.address}
						onChange={(e) =>
							handleEditChange(row.id, e.target.name, e.target.value)
						}
						placeholder='Address'
						size='sm'
					/>
				) : (
					row.address
				),
		},
		{
			id: 7,
			name: 'Actions',
			cell: (row) =>
				editableRowId === row.id && editon === false ? (
					<Flex>
						<Button
							variant='ghost'
							colorScheme='red'
							onClick={() => setEditableRowId('')} // Pass the task ID to softDeleteTask
						>
							<MdClose />
						</Button>
						<Button
							variant='ghost'
							colorScheme='blue'
							onClick={() => saveChanges(row)}
						>
							<MdCheck />
						</Button>
					</Flex>
				) : (
					<>
						<Menu>
							<MenuButton
								as={IconButton}
								aria-label='Options'
								icon={<BiDotsVertical />}
								variant='ghost'
								size='sm'
							/>
							<MenuList>
								<MenuItem onClick={() => softDeleteTask(row.id)}>
									<Button
										variant='ghost'
										colorScheme='red'
									// Pass the task ID to softDeleteTask
									>
										<MdDelete />
									</Button>
									Delete
								</MenuItem>
								<MenuItem onClick={() => openModal(row)}>
									<Button variant='ghost' colorScheme='blue'>
										<BsFillPlayFill />
									</Button>
									Screening
								</MenuItem>
								<MenuItem onClick={() => setEditableRowId2(row)}>
									<Button variant='ghost' colorScheme='blue'>
										<MdEdit />
									</Button>
									Edit
								</MenuItem>
								<MenuItem onClick={() => openMore(row)}>
									<Button variant='ghost' colorScheme='blue'>
										<BiAddToQueue />
									</Button>
									More info
								</MenuItem>
								{/* Add more options as needed */}
							</MenuList>
						</Menu>
					</>
				),
		},
	]

	const getUploadPanel = () => {
		

		// Function to handle document upload
		const handleDocumentUpload = () => {
			fileInputRef.current.click()
		}

		const viewFile = (extension) => {
			const fileData = JSON.stringify({ label: 'Testing', value: 'Test' })
			const blob = new Blob([fileData], { type: 'text/plain' })
			const url = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.download = 'user-info.' + extension
			link.href = url
			link.click()
		}

		const deleteDocument = (id) => {
			setDocumentsFile(documentsFile.filter((document) => document.id !== id))
			toast({
				title: 'Document deleted successfully',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'success',
			})
		}

		return (
			<TabPanel height='500px' p={8} sx={{ width: '100%' }}>
				<Flex alignItems={'center'} justifyContent={'space-between'}>
					<Box>
						Uploaded documents for <b>{editableRowname}</b>
					</Box>
					<IconButton
						mt={4}
						colorScheme='blue'
						aria-label='Upload Document'
						icon={<AddIcon />}
						onClick={handleDocumentUpload}
					/>
					<input type='file' ref={fileInputRef} style={{ display: 'none' }} />
				</Flex>
				<Box overflow={'auto'}>
					<Table variant='striped' colorScheme='gray'>
						<Thead>
							<Tr>
								<Th fontWeight={'bold'}>Document Name</Th>
								<Th fontWeight={'bold'}>Size</Th>
								<Th fontWeight={'bold'}>Uploaded By</Th>
								<Th fontWeight={'bold'}>Uploaded At</Th>
								<Th></Th>
							</Tr>
						</Thead>
						<Tbody>
							{documentsFile.map((document) => (
								<Tr key={document.id}>
									<Td>{document.name}</Td>
									<Td>{document.size}</Td>
									<Td>{document.uploadedBy}</Td>
									<Td>{document.uploadedAt}</Td>
									<Td>
										<Flex gap={5}>

										<IconButton
											onClick={() => viewFile(document.extension)}
											colorScheme='blue'
											aria-label='View Document'
											icon={<ViewIcon />}
										/>
										<IconButton
											onClick={() => deleteDocument(document.id)}
											colorScheme='red'
											aria-label='Delete Document'
											icon={<MdDelete />}
										/>
										</Flex>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			</TabPanel>
		)
	}

	const getNotesPanel = () => {


		return (
			<TabPanel height='500px' p={8} sx={{ width: '100%' }}>
				<FormControl mt={4}>
					<FormLabel>Notes</FormLabel>
					<Textarea placeholder='Add notes' rows={10} onChange={(e) => setNote(e.target.value)} value={note} />
				</FormControl>
			</TabPanel>
		)
	}

	const getMoreInfoPanel = () => {
		return (
			<TabPanel height='500px' p={8} sx={{ width: '100%' }}>
				<FormControl mt={4}>
					<FormLabel>House Size Preference</FormLabel>
					<Input
						id='Prices'
						placeholder='1200 square feet'
						type='text'
						value={moreinfo.prices}
						onChange={(e) => setMoreinfo({ ...moreinfo, prices: e.target.value })}
					/>
				</FormControl>
				<FormControl mt={2}>
					<FormLabel htmlFor='Budget'>Budget</FormLabel>
					<Input id='Budget' placeholder='$10200' type='number' 
					value={moreinfo.budget}
					onChange={(e) => setMoreinfo({ ...moreinfo, budget: e.target.value })}
					/>
				</FormControl>
				<FormControl mt={2}>
					<FormLabel htmlFor='proximity'>Proximity</FormLabel>
					<Input id='proximity' placeholder='Enter proximity' 
					value={moreinfo.proximity}
					onChange={(e) => setMoreinfo({ ...moreinfo, proximity: e.target.value })}
					/>
				</FormControl>
				<FormControl mt={2}>
					<FormLabel htmlFor='locations'>Prefered locations</FormLabel>
					<Input id='locations' placeholder='Hackensack, NJ, 07601, USA' 
					value={moreinfo.locations}
					onChange={(e) => setMoreinfo({ ...moreinfo, locations: e.target.value })}
					/>
				</FormControl>
			</TabPanel>
		)
	}
	// <Box><Plaidlink/></Box>

	const getClientCountInCurrentMonth = (clientList, dateField) => {
		const currentDate = new Date()
		const currentMonth = currentDate.getMonth() + 1
		const currentYear = currentDate.getFullYear()

		return clientList.filter((client) => {
			const dateObj = new Date(client[dateField])
			const month = dateObj.getMonth() + 1
			const year = dateObj.getFullYear()
			return month === currentMonth && year === currentYear
		}).length
	}

	const clientsThisMonth = useMemo(() => {
		return getClientCountInCurrentMonth(clientsList, 'createdAt')
	}, [clientsList])

	const clientsActiveThisMonth = useMemo(() => {
		return getClientCountInCurrentMonth(clientsList, 'lastactive')
	}, [clientsList])

	const handleSearch = (e) => {
		setSearch(e.target.value)
		setClientsList(clientsList.filter((client) => client.name.includes(e.target.value)))
		if (e.target.value === '') {
			getClientsList(JSON.parse(localStorage.getItem('logged_in_user'))?.email)

		}
	}

	const saveNotes = async () => {
		try {
			const save = await axios.put(process.env.REACT_APP_BACKEND + '/clients/savenotes/' + curruser.id, { notes: note })
			getClientsList(JSON.parse(localStorage.getItem('logged_in_user'))?.email)

			toast({
				title: 'Notes saved successfully',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'success',
			})
		} catch (error) {
			console.log('Error in saveNotes:', error)
			toast({
				title: error.message ? error.message : 'Something went wrong',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'error',
			})

		}
		// console.log(curruser)
	}

    const customStyles = {
        headCells: {
            style: {
                fontWeight: '300',
                textTransform: 'uppercase',
                fontSize: '14px',
                color: 'gray',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                color: 'black',
                fontWeight: '500',
                textAlign: 'center',
            },
        },
    }


	const saveMoreInfo = async () => {
		try {
			const save = await axios.put(process.env.REACT_APP_BACKEND + '/clients/savemoreinfo/' + curruser.id, { moreinfo })
			getClientsList(JSON.parse(localStorage.getItem('logged_in_user'))?.email)

			toast({
				title: 'More info saved successfully',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'success',
			})
		} catch (error) {
			console.log('Error in saveMoreInfo:', error)
			toast({
				title: error.message ? error.message : 'Something went wrong',
				variant: 'solid',
				isClosable: true,
				position: 'top-right',
				status: 'error',
			})
		}
	
	}

	return (
		<>
			{isLoading ? (
				<Flex alignItems={'center'} justifyContent={'center'} width={'100%'}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor='gray.200'
						color='blue.500'
						size='xl'
					/>
				</Flex>
			) : (
				<>
					<SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={'20px'} mb='20px'>
						<Card borderRadius={'20px'}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={
											<Icon
												w='32px'
												h='32px'
												as={MdBarChart}
												color={brandColor}
											/>
										}
									/>
								}
								name='Total Clients'
								value={clientsList?.length}
							/>
						</Card>
						<Card borderRadius={'20px'}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={
											<Icon
												w='32px'
												h='32px'
												as={MdBarChart}
												color={brandColor}
											/>
										}
									/>
								}
								name='Clients joined this month'
								value={clientsThisMonth}
							/>
						</Card>
						<Card borderRadius={'20px'}>
							<MiniStatistics
								startContent={
									<IconBox
										w='56px'
										h='56px'
										bg={boxBg}
										icon={
											<Icon
												w='32px'
												h='32px'
												as={MdArrowUpward}
												color={brandColor}
											/>
										}
									/>
								}
								name='Active clients this month'
								value={clientsActiveThisMonth}
							/>
						</Card>
					</SimpleGrid>
					<Card position='relative' p={5} mt={4} borderRadius={'20px'}>
						<Flex
							alignItems={'center'}
							justifyContent={'flex-end'}
							width={'100%'}
						>
							<Box width={{ sm: '100%', md: '25%' }} mb={6}>
								<InputGroup>
									<InputLeftElement
										pointerEvents='none'
										children={<FaSearch color='gray.300' />}
									/>
									<Input
										placeholder='Search...'
										borderRadius='20px'
										width={'100%'}
										onChange={handleSearch}
									/>
								</InputGroup>
							</Box>
						</Flex>
						<Modal isOpen={isModalOpen} onClose={closeModal}>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>Screening</ModalHeader>
								<ModalBody>
									<FormControl mt={4}>
										<FormLabel>
											Perform Screening on {currentclientname}
										</FormLabel>
									</FormControl>
								</ModalBody>
								<ModalFooter>
									<Button colorScheme='blue' mr={3} onClick={runsms}>
										Yes
									</Button>
									<Button variant='ghost' onClick={handleSave}>
										No
									</Button>
								</ModalFooter>
							</ModalContent>
						</Modal>
						<Modal
							size={{ base: 'md', md: 'xl', lg: '6xl' }}
							isOpen={isModalMoreOpen}
							onClose={closeMoreModal}
						>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>Client</ModalHeader>
								<ModalBody>
									<Tabs sx={{ width: '100%' }}>
										<TabList>
											<Tab onClick={() => setTabId(0)}>Upload</Tab>
											<Tab onClick={() => setTabId(1)}>Notes</Tab>
											<Tab onClick={() => setTabId(2)}>More Info</Tab>
											<Tab onClick={() => setTabId(3)}>AI Assist</Tab>
										</TabList>
										<TabPanels>
											{getUploadPanel()}
											{getNotesPanel()}
											{getMoreInfoPanel()}
											<TabPanel>
												<ClientProfile selectedClient={curruser} />
											</TabPanel>
										</TabPanels>
									</Tabs>
								</ModalBody>
								<ModalFooter>
									{tabId === 1 && (
										<Button variant='outline' color='blue' onClick={() => saveNotes()} mr={5}>
											Save
										</Button>
									)}
									{tabId === 2 && (
										<Button variant='outline' color='blue' onClick={() => saveMoreInfo()} mr={5}>
											Save
										</Button>
									)}
									<Flex gap={5}>
										<Button variant='outline' onClick={handleclose} colorScheme={"green"}>
											Close
										</Button>
										{/* <Button variant='outline' onClick={handleclose} colorScheme={"messenger"}>
											save
										</Button> */}
									</Flex>
								</ModalFooter>
							</ModalContent>
						</Modal>
						<DataTable
							className={isMobile ? 'view-table-mobile' : 'view-table'}
							columns={columns}
							data={clientsList.filter((client) => !client.isDeleted)}
							sortIcon={<MdArrowUpward />}
							pagination
							highlightOnHover
							responsive
							pointerOnHover
							customStyles={customStyles}
						/>
					</Card>
				</>
			)}
		</>
	)
}

export default ViewTable
