import {
	Box,
	Divider,
	Heading,
	List,
	ListIcon,
	ListItem,
	Text,
} from '@chakra-ui/layout'
import { Button, Card, CardBody, CardHeader, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { MdCheckCircle } from 'react-icons/md'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

const plans = [
	{
		id: 0,
		name: 'Standard Plan',
		type: 1,
		description: 'Standard subscription plan with basic access',
		price: 1.99,
		frequency: 'monthly',
		transcost: 1,
		popular: false,
	},
	{
		id: 1,
		name: 'Premium Plan',
		type: 2,
		description: 'Premium subscription plan with full access',
		price: 7.99,
		frequency: 'monthly',
		transcost: 2,
		popular: true,
	},
	{
		id: 3,
		name: 'Enterprise Plan',
		type: 3,
		description: 'Enterprise subscription plan with advanced features',
		price: 15.99,
		frequency: 'monthly',
		transcost: 3,
		popular: false,
	},
]
//developers are repeating each others work

export const AccountPlans = (props) => {
	const [selectedPlan, setSelectedPlan] = useState('')

	const { user } = useAuth0()
	const toast = useToast()

	const handleSubscription = async () => {
		try {
			if (selectedPlan) {
				const id =
					selectedPlan === 'Standard Plan'
						? 0
						: selectedPlan === 'Premium Plan'
						? 1
						: 2

				const res = await axios.patch(
					`${process.env.REACT_APP_BACKEND}/user/addSubscription/${user.sub}`,
					{
						plan: selectedPlan,
					}
				)

				if (res.data) {
					const userRes = res.data.user
					console.log('User Res',userRes)
					console.log('User Res',userRes)

					axios
						.post(`${process.env.REACT_APP_BACKEND}/agents/create`, {
							name: userRes.name,
							address: '',
							email: userRes.email,
							subscribed: true,
							phonenumber: '',
							accCode: userRes.accCode,
						})
						.then((res) => {
							console.log(res, 'Resp Agents')
						})
						.catch((err) => {
							toast({
								title: 'Error',
								description: 'Internal server error',
								status: 'error',
								duration: 3000,
								position: 'top-right',
							})
						})

					axios
						.post(
							`${process.env.REACT_APP_BACKEND}/account/addAccountDetails`,
							{
								subscription: selectedPlan,
								name: userRes.name,
								address: '',
								companyname: userRes.company_name,
								email: userRes.email,
								paymentmethod: '',
								settings: {
									plan: selectedPlan,
									language: 'English',
									showFullName: false,
									notifyAllUsers: false,
								},
								userid: user.sub,
								accCode: userRes.accCode,

							}
						)
						.then((res) => {
							console.log(res, 'Resp')
						})
						.catch((err) => {
							toast({
								title: 'Error',
								description: 'Internal server error',
								status: 'error',
								duration: 3000,
								position: 'top-right',
							})
						})
				}

				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND}/subscription/create-checkout-session`,
					{
						planId: id,
					}
				)
				if (response.data) {
					window.location.href = response.data.url
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			gap={'32px'}
			alignItems={'center'}
			justifyContent={'center'}
			height={'100vh'}
			width={'100vw'}
		>
			<Heading color={'blue.400'}>
				<Text color={'black'} as={'span'}>
					Scale
				</Text>{' '}
				&{' '}
				<Text color={'black'} as={'span'}>
					Grow
				</Text>{' '}
				Realty
			</Heading>
			<Text fontSize={'4xl'} as={'b'}>
				Which package would you like?2
			</Text>

			<Box
				display={'flex'}
				gap={'48px'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				{plans.map((plan, idx) => (
					<Card
						key={`plan-${plan.name}-${idx}`}
						minWidth={'300px'}
						textAlign={'center'}
						border={
							selectedPlan === plan.name ? '1px solid black' : '1px solid gray'
						}
						position={'relative'}
						transition={'all 0.3s'}
						boxSizing='border-box'
						transform={selectedPlan === plan.name && 'scale(1.1)'}
					>
						{plan.popular && (
							<Box
								position={'absolute'}
								top={-5}
								right={-5}
								display={'flex'}
								alignItems={'center'}
								border={'2px solid green'}
								backgroundColor={'green.200'}
								color={'green'}
								borderRadius={'12px'}
								width={'100px'}
								zIndex={2}
							>
								<Text fontSize={'sm'} as={'b'}>
									Most popular one
								</Text>
							</Box>
						)}
						<CardHeader>
							<Heading fontSize={'xl'}>{plan.name}</Heading>
						</CardHeader>
						<Divider />
						<CardBody>
							<Text fontSize={'l'} color={'gray'}>
								${' '}
								<Text fontSize={'3xl'} as={'b'} color={'black'}>
									{plan.price}
								</Text>{' '}
								/{plan.frequency}
							</Text>

							<Text fontSize={'l'} color={'black'}>
								Total ${plan.price} + ${plan.transcost} (charge){' '}
								{plan.frequency}
							</Text>
							<Text fontSize={'l'} color={'black'}>
								Billed monthly{' '}
							</Text>
						</CardBody>
						<Divider />
						<Text fontSize={'l'} as={'b'}>
							{plan.name} plan includes :
						</Text>
						{/* <List spacing={3} textAlign={'start'} padding={'8px 16px'}>
							{Array.from(plan.specs).map((item, ind) => (
								<ListItem
									display={'flex'}
									alignItems={'center'}
									key={`spec-item-${ind}`}
								>
									<ListIcon as={MdCheckCircle} color={'green.500'} />
									{item}
								</ListItem>
							))}
						</List> */}
						<Text padding={'8px 16px'}>{plan.description}</Text>
						<Divider />
						<Button
							color={'black'}
							borderRadius={8}
							onClick={() => {
								setSelectedPlan(plan.name)
							}}
							disabled={selectedPlan === plan.name}
						>
							Select
						</Button>
					</Card>
				))}
			</Box>
			<Button
				colorScheme={'telegram'}
				minWidth={'400px'}
				borderRadius={'8px'}
				color={'white'}
				isDisabled={!selectedPlan}
				onClick={handleSubscription}
			>
				Continue
			</Button>
		</Box>
	)
}
